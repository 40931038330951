.pagination {
    margin-top: $space-s;
    font-weight: normal;

    li {
        $a: 2.75rem;

        float: left;
        border: 1px solid currentColor;
        margin-right: .5rem;
        margin-bottom: .5rem;

        &:hover {
            border-color: get-color(primary);
            background: get-color(primary);
            a {
                color: white;
            }
        }

        &, a {
            width: $a;
            height: $a;
            display: flex !important;
            justify-content: center;
            align-items: center;
            color: $medium-gray;
            &:after, &:before {
                color: inherit;
                margin: 0;
            }
        }

        a {
            &:hover {
                background: transparent;
            }
        }
        &.current {
            color: get-color(primary);
            background: transparent;
            &:hover {
                color: white;
                background: get-color(primary);
            }
        }
    }
    @include breakpoint(medium) {
        margin-top: $space-m;
    }
}

.pagination-previous a{
    //@extend .icon-chevron-left;
    &:before, .pagination-previous.disabled {
    //@extend .icon-font;

}}

.pagination-next a::after, .pagination-next.disabled::after {
    //@extend .icon-font;
    //@extend .icon-chevron-right;
}

.pagination .ellipsis::after {
    color: $medium-gray;
}
