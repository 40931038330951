.panel {
    position: relative;
    margin: 0 0 rem-calc(10px);
    @include breakpoint(medium) {
        margin: 0 0 rem-calc(20px);
    }
    @include breakpoint(large) {
        margin: 0 0 rem-calc(40px);
    }
    border-radius: 0;
    box-sizing: border-box;
    //paddings
    &_padding-none {
        @include panel-padding(0px, 0px, 0);
        // add some Padding To text & Images inside Panels
        .textpic-text {
            @include panel-padding(10px, 12px, 15px);
        }

        .textpic-item_caption {
            padding: 0 rem-calc(10px);
            @include breakpoint(medium) {
                padding: 0 rem-calc(12px);
            }
            @include breakpoint(large) {
                padding: 0 rem-calc(15px);
            }
        }
    }

    &_padding-small {
        @include panel-padding(10px, 12px, 15px);
    }

    &_padding-medium {
        @include panel-padding(15px, 20px, 25px);
    }

    &_padding-large {
        @include panel-padding(20px, 25px, 30px);
    }

    &_bg-transparent {
        background-color: transparent;
    }

    &_bg-white {
        background-color: $white;
    }

    &_bg-white-overflow {
        position: relative;
        padding-left: 0;
        padding-right: 0;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            max-width: $global-width;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            right: 0;
            background-color: $white;
        }
    }

    &_bg-light-grey {
        background-color: $light-gray;
    }

    &_bg-medium-grey {
        background-color: $medium-gray;
    }

    &_bg-dark-grey {
        background-color: $dark-gray;
    }

    &_bg-black {
        background-color: $black;
    }

    &_bg-primary {
        background-color: get-color(primary);
    }

    &_bg-secondary {
        background-color: get-color(secondary);
    }
    //shadows
    &_shadow {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        @include breakpoint(medium) {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        @include breakpoint(large) {
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
        }
    }

    &_nomargin {
        margin: 0;
    }

    &_pullup {
        margin-top: -$space-s*2;
        @include breakpoint(medium) {
            margin-top: -$space-m*2;
        }
        @include breakpoint(large) {
            margin-top: -$space-l*2;
        }
    }
    //borders
    &_border {
        border: 1px solid $medium-gray;
    }

    &_hover:hover {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        @include transition_ease-in(all);
        @include breakpoint(medium) {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }
        @include breakpoint(large) {
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
        }
    }
    // height modificator
    &_height_100 {
        height: calc(100% - 1rem);
    }
}
