.breadcrumbs {
    letter-spacing: 0.1em;
    margin:0;
    font-size:rem-calc(14px);
    line-height:rem-calc(16px);
    li{
        color:#fff;
        text-transform: uppercase;
        font-size:rem-calc(14px);
        line-height:rem-calc(16px);
        &:not(:last-child) {
            &::after {
                font-size:rem-calc(12px);
                line-height:rem-calc(16px);
                position: relative;
                margin: 0 $breadcrumbs-item-margin;
                font-family: 'icomoon';
                opacity: 1;
                content: '\e904';
                color: #B9CE37;
                vertical-align: bottom;
            }
        }
    }
    a {
        color:#fff;
    }

    &_subpage{
        position: relative;
        max-width: $global-width;
        margin-left: auto;
        margin-right: auto;
        padding-left: $section-gutter-small;
        padding-right: $section-gutter-small;
        @include breakpoint(medium) {
            padding-left: $section-gutter-medium;
            padding-right: $section-gutter-medium;
        }
        @include breakpoint(large) {
            padding-left: $section-gutter-large;
            padding-right: $section-gutter-large;
        }
        nav {
            position: absolute;
            z-index: 20;
            top:rem-calc(28px);
            left:$section-gutter-small;
            right:$section-gutter-small;
            @include breakpoint(medium) {
                left: $section-gutter-medium;
                right: $section-gutter-medium;
            }
            @include breakpoint(large) {
                left: $section-gutter-large;
                right: $section-gutter-large;
            }
        }
    }
    &_center{
        margin: 0 auto 1rem auto;
        display: table;

        li {
            display: inline-block;
        }
    }

    @include breakpoint(medium) {
        li {
            font-size:rem-calc(16px);
            line-height:rem-calc(17px);
            &:not(:last-child) {
                &::after {
                    font-size:rem-calc(14px);
                    line-height:rem-calc(17px);
                }
            }
        }
    }
    @include breakpoint(large) {
        li {
            font-size:rem-calc(20px);
            line-height:rem-calc(22px);
            &:not(:last-child) {
                &::after {
                    font-size:rem-calc(17px);
                    line-height:rem-calc(22px);
                }
            }
        }
    }
}

.twe-breadcrumbs {
    display: flex;
    align-items:flex-end;
    color:#102E48;
    margin-bottom: 2rem;
    > div:first-child {
        margin-right: 1rem;
    }
    .breadcrumbs {
        a,
        li {
            color: #102E48;
        }
    }

    font-size:rem-calc(14px);
    line-height:rem-calc(16px);
    @include breakpoint(medium) {
        font-size:rem-calc(16px);
        line-height:rem-calc(17px);
    }
    @include breakpoint(large) {
        font-size:rem-calc(20px);
        line-height:rem-calc(22px);
    }
}