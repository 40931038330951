
.download-cards {
  display:flex;
  flex-wrap:wrap;
  justify-content: flex-start;
  align-items:stretch;
  gap:30px;
}
.download-card {
  width: 100%;
  flex: 1 1 100%;
  min-height: 190px;
  overflow: hidden;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 96%, 55% 100%, 0 95%);
  background: #102E48;
  box-sizing: border-box;
  @include breakpoint(medium) {
    min-height: 300px;
    max-width: calc(50% - 15px);
    flex: 0 0 50%;
  }
  @include breakpoint(large) {
    max-width: calc(20% - 24px);
    flex: 0 0 20%;
  }
  .content {
    padding: 20px 25px;
    position: relative;
    z-index: 2;
  }
  p {
    font-size:rem-calc(15px);
    line-height: rem-calc(16px);
    text-transform: uppercase;
    color:#fff;
    margin-bottom:3px;

    @include breakpoint(medium) {
      font-size:rem-calc(16px);
      line-height: rem-calc(17px);
    }
    @include breakpoint(large) {
      font-size:rem-calc(14px);
      line-height: rem-calc(16px);
    }
  }
  h2,h3,h4 {
    font-size:rem-calc(25px);
    line-height: rem-calc(28px);
    text-transform: uppercase;
    color:#fff;
    //word-break: break-all;
    overflow-wrap: break-word;
    hyphens: auto;
    @include breakpoint(medium) {
      font-size:rem-calc(26px);
      line-height: rem-calc(30px);
    }
    @include breakpoint(large) {
      font-size:rem-calc(22px);
      line-height: rem-calc(25px);
    }
  }
  .btn {
    position: absolute;
    bottom:25px;
    left:40px;
    right:40px;
    font-size:rem-calc(14px);
    line-height: rem-calc(16px);
    color:#102E48;
    padding:9px;
    @include breakpoint(medium) {
      left:35px;
      right:35px;
      font-size:rem-calc(20px);
      line-height: rem-calc(22px);
    }
    @include breakpoint(medium) {
      left:20px;
      right:20px;
    }
  }
  .img_bg {
    position: absolute;
    top:100px;
    left:35px;
    right:35px;
    display:block;
    opacity: .2;
    img {
      width:100%;
      height:auto;
      vertical-align: middle;
    }
  }
  .content {
    height: 100%;
  }
}


.card-imglinks {
  .card-imglinks-items {
    display: flex;
    flex-wrap: wrap;
    margin:0 -15px;
    justify-content: center;
    .card-imglink {
      position: relative;
      flex-basis: calc(100% - 30px);
      margin: 15px;
      border: 1px solid;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      background: #102E48;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
        position: relative;
        z-index: 1;
        background: transparent linear-gradient(0deg, #102E48 0%, #03090E00 100%) 0% 0% no-repeat padding-box;
        transition: opacity .4s ease;
        opacity: 0.7;
      }
      &:after {
        content: '';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: transparent linear-gradient(90deg, #102E48 0%, #03090E00 100%) 0% 0% no-repeat padding-box;
        opacity: 0.7;
        z-index: 1;
      }
      .img_bg {
        display: block;
        position: absolute;
        overflow: hidden;
        left: 0;
        top:0;
        right:0;
        bottom:0;
        z-index: 0;
        img {
          width: 100%;
          min-height:100%;
          height: auto;
          object-fit: cover;
          vertical-align: middle;
          display: inline-block;
          transition: transform .5s ease;
        }
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 3;
        font-size:rem-calc(39px);
        line-height: rem-calc(43px);
        color:#fff!important;
        display:flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-align: center;
        padding: 20px;
        //word-break: break-all;

        @include breakpoint(medium) {
          font-size:rem-calc(23px);
          line-height: rem-calc(26px);
        }
        @include breakpoint(large) {
          font-size:rem-calc(48px);
          line-height: rem-calc(52px);
        }
      }
      &:hover {
        .img_bg {
          img {
            transform: scale(1.1);
          }
        }
        &:before {
          opacity: 0.4;
        }
      }
      @include breakpoint(medium) {
        flex-basis: calc(33% - 30px);
      }
    }
  }
  &.card-imglinks--4 {
    .card-imglinks-items {
      .card-imglink {
        @include breakpoint(medium) {
          flex-basis: calc(25% - 30px);
          a {
            font-size:rem-calc(34px);
            line-height: rem-calc(38px);
          }
        }
      }
    }
  }
  &.card-imglinks--autohidden {
    &:not(.card-imglinks--4 ){
      .card-imglinks-items {
        .card-imglink:not(:nth-child(-n+3)) {
          display: none;
        }
      }
    }
    &.card-imglinks--4 {
      .card-imglinks-items {
        .card-imglink:not(:nth-child(-n+4)) {
          display: none;
        }
      }
    }
  }
}


.card-lightbox {
  .card-lightbox-items {
    display: flex;
    flex-wrap: wrap;
    margin:0 -15px;
    justify-content: center;
    .card-lightbox-item {
      position: relative;
      flex-basis: calc(100% - 30px);
      margin: 15px;
      border: 1px solid;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      background: #102E48;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
        position: relative;
        z-index: 1;
        //background: transparent linear-gradient(0deg, #102E48 0%, #03090E00 100%) 0% 0% no-repeat padding-box;
        //transition: opacity .4s ease;
        //opacity: 0.7;
      }
      //&:after {
      //  content: '';
      //  position: absolute;
      //  top:0;
      //  left:0;
      //  right:0;
      //  bottom:0;
      //  background: transparent linear-gradient(90deg, #102E48 0%, #03090E00 100%) 0% 0% no-repeat padding-box;
      //  opacity: 0.7;
      //  z-index: 1;
      //}
      .img_bg {
        display: block;
        position: absolute;
        overflow: hidden;
        left: 0;
        top:0;
        right:0;
        bottom:0;
        z-index: 0;
        img {
          width: 100%;
          min-height:100%;
          height: auto;
          object-fit: cover;
          vertical-align: middle;
          display: inline-block;
          transition: transform .5s ease;
        }
      }
      //a {
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  height: 100%;
      //  width: 100%;
      //  z-index: 3;
      //  font-size:rem-calc(39px);
      //  line-height: rem-calc(43px);
      //  color:#fff!important;
      //  display:flex;
      //  justify-content: center;
      //  align-items: center;
      //  font-weight: bold;
      //  text-align: center;
      //  padding: 20px;
      //  //word-break: break-all;
      //
      //  @include breakpoint(medium) {
      //    font-size:rem-calc(23px);
      //    line-height: rem-calc(26px);
      //  }
      //  @include breakpoint(large) {
      //    font-size:rem-calc(48px);
      //    line-height: rem-calc(52px);
      //  }
      //}
      //&:hover {
      //  .img_bg {
      //    img {
      //      transform: scale(1.1);
      //    }
      //  }
      //  &:before {
      //    opacity: 0.4;
      //  }
      //}

      .card-lightbox-open,
      .card-lightbox-link {
        position:absolute;
        z-index:2;
        width: 47px;
        height: 47px;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:rem-calc(24px);
        border-radius: 50%;
        cursor: pointer;
        line-height: 1;
        color:#fff;
        transition: all .3s ease;
        box-shadow:0 0 0 0 rgba(255,255,255,0);
        i {
          vertical-align: middle;
          &:before {
            vertical-align: middle;
          }
        }
        &:hover {
          background: #fff;
          color:#102E48;
          box-shadow:0 3px 10px 1px rgba(0,0,0,.4);
        }
      }

      .card-lightbox-open {
        bottom:37px;
        left:39px;
      }
      .card-lightbox-link {
        bottom:37px;
        right:39px;
      }


      @include breakpoint(medium) {
        flex-basis: calc(33% - 30px);
        .card-lightbox-open {
          bottom:17px;
          left:19px;
        }
        .card-lightbox-link {
          bottom:17px;
          right:19px;
        }
        .card-lightbox-open,
        .card-lightbox-link {
          width: 37px;
          height: 37px;
          font-size:rem-calc(18px);
        }
      }
      @include breakpoint(large) {
        .card-lightbox-open {
          bottom:37px;
          left:39px;
        }
        .card-lightbox-link {
          bottom:37px;
          right:39px;
        }
        .card-lightbox-open,
        .card-lightbox-link {
          width: 47px;
          height: 47px;
          font-size:rem-calc(24px);
        }
      }
    }
  }
  &.card-lightbox--4 {
    .card-lightbox-items {
      .card-lightbox-item {
        @include breakpoint(medium) {
          flex-basis: calc(25% - 30px);
          a {
            font-size:rem-calc(34px);
            line-height: rem-calc(38px);
          }
        }
      }
    }
  }
  &.card-lightbox--autohidden {
    &:not(.card-lightbox--4 ){
      .card-lightbox-items {
        .card-lightbox-item:not(:nth-child(-n+3)) {
          display: none;
        }
      }
    }
    &.card-lightbox--4 {
      .card-lightbox-items {
        .card-lightbox-item:not(:nth-child(-n+4)) {
          display: none;
        }
      }
    }
  }
}


//.card-product {}
.card-product-header {
  display:flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 241px;
  flex-direction: column;
  .card-product-preview {
    height: 141px;
    width: 141px;
    border-radius:50%;
    overflow:hidden;
    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      vertical-align: middle;
      display: inline-block;
    }
    // 28
    // 31
    // #9A9999
  }
}
.card-product-body-info {
  font-size:rem-calc(22px);
  line-height: rem-calc(26px);
  color:#9A9999;
  p {
    font-size:rem-calc(22px);
    line-height: rem-calc(26px);
  }
  .item {
    padding:15px 0;
    border-bottom: 1px solid #707070;
  }
}
.card-product-body-links {
  padding:15px 0;
  border-bottom: 1px solid #707070;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  .btn {
    font-size: rem-calc(22px);
    line-height: rem-calc(31px);
    font-weight: 300;
    margin-bottom:0;
    //&:not(:nth-child(1)) {
    //  margin-left: 30px;
    //}
  }
  .btn__download_icon {
    padding-left: 56px;
    background-image: url(../img/twe/twe-download-icon.svg);
    background-position: 2px 50%;
    background-repeat: no-repeat;
  }
}
.card-product-body{
  margin-top: 0;
}

.card-product-body-contact {
  padding:15px 0;
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  .card-product-contact-icon {
    flex:0 0 auto;
    width:88px;
    height: 88px;
    background: #B9CE37;
    border-radius:50%;
    overflow: hidden;
    position: relative;
    margin-right: 25px;
    img {
      position: absolute;
      top:10%;
      width: 80%;
      height: 80%;
      left:10%;
      object-fit: cover;
      vertical-align: middle;
      display: inline-block;
    }
  }
  .btn__mail_icon {
    font-size: rem-calc(22px);
    line-height: rem-calc(31px);
    font-weight: 300;
    background-image: url(../img/twe/twe-mail-icon.svg);
    padding-left: 56px;
    background-position: 2px 50%;
    background-repeat: no-repeat;
  }
  .card-product-contact-text {
      font-size:rem-calc(22px);
      line-height: rem-calc(31px);
      color:#102E48;
      h4 {
        color:#102E48;
      }
      p {
        color: #707070;
      }
      h4, p {
        font-weight: 300;
        font-size:rem-calc(22px);
        line-height: rem-calc(31px);
        margin-bottom:0;
      }
      p:last-of-type {
        margin-top: 2rem;
      }
  }
}


@include breakpoint(medium) {
  .card-product-body-contact,
  .card-product-body-links {
    padding: 45px 0;
  }
  .card-product-body-links {
    gap:30px;
    .btn {
      font-size: rem-calc(26px);
    }

    .btn__download_icon {
      padding-left: 66px;
      background-position: 12px 50%;
    }
  }
  .card-product-body-contact {
    .card-product-contact-icon {
      width: 128px;
      height: 128px;
      margin-right: 35px;
    }
    .btn__mail_icon {
      font-size: rem-calc(26px);
      padding-left: 66px;
      background-position: 12px 50%;
    }
  }
  .card-product-header {
    justify-content: space-between;
    flex-direction: row;
    .card-product-preview {
      height: 141px;
      width: 141px;
    }
  }
  .card-product-body{
    margin-top: -80px;
  }
  .card-product-body-info {
    font-size:rem-calc(24px);
    line-height: rem-calc(28px);
    p {
      font-size:rem-calc(24px);
      line-height: rem-calc(28px);
    }
    .item {
      padding: 30px 0;
    }
  }

  .card-product-contact-text {
    font-size:rem-calc(28px);
    line-height: rem-calc(37px);
    h4, p {
      font-size:rem-calc(28px);
      line-height: rem-calc(37px);
    }
  }
}
@include breakpoint(large) {
  .card-product-header {
    .card-product-preview {
      height: 241px;
      width: 241px;
    }
  }
  .card-product-body-contact {
    .card-product-contact-icon {
      width: 228px;
      height: 228px;
    }
  }
  .card-product-body-links {
    .btn {
      margin-bottom: 0;
    }
  }
  .card-product-body-info {
    font-size:rem-calc(28px);
    line-height: rem-calc(31px);
    p {
      font-size:rem-calc(28px);
      line-height: rem-calc(31px);
    }
  }
}


.card-lightbox-content {
  display: none; /* Ukryj lightbox domyślnie */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //background-color: rgba(255,255,255, 1);
  color: #000000;
  width: 90%;
  height:94%;
  max-width: 540px;
  max-height: 890px;
  z-index: 9999;
  .card-lightbox-close {
    position: absolute;
    top: 3px;
    right: 13px;
    font-size: 36px;
    line-height: 1;
    color: #000;
    cursor: pointer;
    outline: unset;
    border: unset;
    z-index: 4;
  }
  &.active {
    display: block; /* Pokaż lightbox po dodaniu klasy 'active' */
  }
  .lightbox-content-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    .col-img {
      flex:1 0 50%;
      max-height: 320px;
      background-color: #102E48;
      img {
        object-fit: cover;
        width:100%;
        height:100%;
        vertical-align: middle;
      }
    }
    .col-content {
      flex:1 1 50%;
      overflow: hidden;
      padding: 37px 16px 37px 41px;
      position: relative;
      background-color: #fff;
      .label-info {
        font-size:rem-calc(23px);
        line-height: rem-calc(27px);
        color:#102E48;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom:8px;
      }
      &:after {
          content: '';
          display: block;
          height: 55px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      }
      .content-overflow {
        overflow: auto;
        max-height: 100%;
        padding-bottom: 25px;
        position: relative;
        font-size:rem-calc(20px);
        line-height: rem-calc(23px);
        color:#000;
        padding-right:45px;
        p {
          font-size:rem-calc(20px);
          line-height: rem-calc(23px);
          color:#000;
        }

        &::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
          border-radius: 8px;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
          width: 8px;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
          border-radius: 8px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
          background-color: #00000033;
        }

      }
    }
  }
  @include breakpoint(medium) {
    width: 80%;
    height:90%;
    max-width: 1161px;
    max-height: 653px;
    .lightbox-content-wrap {
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      .col-img {
          max-height:unset;
      }
    }
  }
}
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Kolor i przezroczystość overlay */
  z-index: 9998; /* Ustaw wysokość nad lightboxem */
}