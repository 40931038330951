.csc-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &.csc-menu-def {}

    &.csc-menu-1 {}

    &.csc-menu-horizontal {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;

        li {
            flex-shrink: 0;
            flex-grow: 0;
            padding: 0;
        }
        margin-bottom:16px;
        @include breakpoint(medium) {
            flex-wrap: nowrap;
            flex-direction: row;
            li {
                padding:0 7px;
            }
        }
        @include breakpoint(large) {
            flex-wrap: nowrap;
            flex-direction: row;
            margin-bottom:0;
            li {
                padding: 0 14px;
            }
        }
    }
}
