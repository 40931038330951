.section {
    position: relative;

    &__inner {
        @include clearfix;
        max-width: 100vw;
        width: 100%;
        padding-left: $section-gutter-small;
        padding-right: $section-gutter-small;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: visible;
        @include breakpoint(medium) {
            padding-left: $section-gutter-medium;
            padding-right: $section-gutter-medium;
        }
        @include breakpoint(large) {
            padding-left: $section-gutter-large;
            padding-right: $section-gutter-large;
        }
    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        &-white {
            background: rgba(255,255,255, 0.5);
        }

        &-black {
            background: rgba(0,0,0, 0.5);
        }

        &-primary {
            background: rgba(get-color(primary), 0.5);
        }

        &-secondary {
            background: rgba(get-color(secondary), 0.5);
        }
    }
    /* ---------------------- FRames ------------------------ */
    &_default {
        & > .section__inner,
        & > .twe-section-overlay > .section__inner {
            max-width: $global-width;
        }
    }

    &_narrow {
        & > .section__inner,
        & > .twe-section-overlay > .section__inner {
            max-width: $grid-row-width-narrow;
        }
    }

    &_wider {
        & > .section__inner,
        & > .twe-section-overlay > .section__inner {
            max-width: $grid-row-width-expanded;
        }
    }

    &_full-width {}

    &_full-width-expand {
        & > .section__inner,
        & > .twe-section-overlay > .section__inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
    /* ---------------------- Vertical and Horizontal align ------------------------ */
    //
    // &_all-centered {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: center;
    //   align-items: center;
    //
    //   .section__inner {
    //     text-align: center;
    //   }
    //
    // }

    &_vertical-centered {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &_vertical-start {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    &_vertical-end {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    /* ---------------------- Height modificators ------------------------ */
    &_height-full {
        min-height: 100vh;
        height: auto;
        // margin-top: -$header-height-small;
        // padding-top: $header-height-small;
        box-sizing: border-box;
        // @include breakpoint(medium) {
        //     margin-top: -$header-height-medium;
        //     padding-top: $header-height-medium;
        // }
        // @include breakpoint(large) {
        //     margin-top: -$header-height-large;
        //     padding-top: $header-height-large;
        // }
    }

    &_height-half {
        min-height: 50vh;
    }

    &_height-75vh {
        min-height: 75vh;
        height: auto;
        box-sizing: border-box;
    }

    &_height-fixed {
        min-height: rem-calc(100px);
        @include breakpoint(medium) {
            min-height: rem-calc(150px);
        }
        @include breakpoint(large) {
            min-height: rem-calc(450px);
        }
    }

    // Top Paddings (Based on Fibonacci)
    &.pt-extra-small {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include top-padding(10px, 16px, 26px);
        }
    }

    &.pt-small {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include top-padding(15px, 24px, 39px);
        }
    }

    &.pt-medium {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include top-padding(25px, 40px, 65px);
        }
    }

    &.pt-large {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include top-padding(40px, 64px, 104px);
        }
    }

    &.pt-extra-large {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include top-padding(65px, 104px, 169px);
        }
    }
    //Bottom Paddings
    &.pb-extra-small {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include bottom-padding(10px, 16px, 26px);
        }
    }

    &.pb-small {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include bottom-padding(15px, 24px, 39px);
        }
    }

    &.pb-medium {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include bottom-padding(25px, 40px, 65px);
        }
    }

    &.pb-large {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include bottom-padding(40px, 64px, 104px);
        }
    }

    &.pb-extra-large {
        > .section__inner,
        > .twe-section-overlay > .section__inner {
            @include bottom-padding(65px, 104px, 169px);
        }
    }

    // not: twe sections (special)
    &:not([class*="twe-section-"]) {
        // Top margins
        &.mt-extra-small {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include top-margin(10px, 16px, 26px);
            }
        }

        &.mt-small {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include top-margin(15px, 24px, 39px);
            }
        }

        &.mt-medium {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include top-margin(25px, 40px, 65px);
            }
        }

        &.mt-large {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include top-margin(40px, 64px, 104px);
            }
        }

        &.mt-extra-large {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include top-margin(65px, 104px, 169px);
            }
        }

        // Bottom margins
        &.mb-extra-small {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include bottom-margin(10px, 16px, 26px);
            }
        }

        &.mb-small {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include bottom-margin(15px, 24px, 39px);
            }
        }

        &.mb-medium {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include bottom-margin(25px, 40px, 65px);
            }
        }

        &.mb-large {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include bottom-margin(40px, 64px, 104px);
            }
        }

        &.mb-extra-large {
            > .section__inner,
            > .twe-section-overlay > .section__inner {
                @include bottom-margin(65px, 104px, 169px);
            }
        }
    }

    // twe sections (special)
    &[class*="twe-section-"] {
        // Top margins
        &.mt-extra-small {
                @include top-margin(10px, 16px, 26px);
        }

        &.mt-small {
                @include top-margin(15px, 24px, 39px);
        }

        &.mt-medium {
                @include top-margin(25px, 40px, 65px);
        }

        &.mt-large {
                @include top-margin(40px, 64px, 104px);
        }

        &.mt-extra-large {
                @include top-margin(65px, 104px, 169px);
        }
        // Bottom margins
        &.mb-extra-small {
                @include bottom-margin(10px, 16px, 26px);
        }

        &.mb-small {
                @include bottom-margin(15px, 24px, 39px);
        }

        &.mb-medium {
                @include bottom-margin(25px, 40px, 65px);
        }

        &.mb-large {
                @include bottom-margin(40px, 64px, 104px);
        }

        &.mb-extra-large {
                @include bottom-margin(65px, 104px, 169px);
        }
    }

    /* ---------------------- Backgrounds ------------------------ */
    &_bg-none {
        background-color: none !important;
    }

    &_bg-image {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .twe-section-overlay {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
    &_bg-image-size-contain {
        background-size: contain;
        .twe-section-overlay {
            background-size: contain;
        }
    }
    &_bg-image-size-90 {
        background-size: 90% auto;
        background-position: center 80%;
        .twe-section-overlay {
            background-size: 90% auto;
            background-position: center 80%;
        }
        @include breakpoint(medium) {
            background-size: auto 90%;
            background-position: center center;
            .twe-section-overlay {
                background-size: auto 90%;
                background-position: center center;
            }
        }
    }

    &_bg-video {
        .section__inner {
            position: static;
        }
    }

    &_bg-white {
        background-color: $white;
    }

    &_bg-light-grey {
        background-color: $light-gray;
    }

    &_bg-medium-grey {
        background-color: $medium-gray;
    }

    &_bg-dark-grey {
        background-color: $dark-gray;
    }

    &_bg-black {
        background-color: $black;
    }

    &_bg-primary:not(.dark) {
        background-color: get-color(primary);
    }
    &_bg-primary.dark {
        background-color: #102E48;
    }

    &_bg-secondary {
        background-color: get-color(secondary);
    }
    // &_bg-gradient-radial-center {
    //   background: $black;
    //   background-image: url("../img/rauschen-fein.png"), -webkit-radial-gradient(circle, $dark-gray, $black);
    //   background-image: url("../img/rauschen-fein.png"), radial-gradient(circle, $dark-gray, $black);
    //
    // }
    //
    // &_bg-gradient-radial-top-left {
    //   background: $black;
    //   background-image: url("../img/rauschen-fein.png"), -webkit-radial-gradient(30% 30% $dark-gray, $black);
    //   background-image: url("../img/rauschen-fein.png"), radial-gradient(at 30% 30%, $dark-gray, $black);
    //
    // }
    //
    // &_bg-gradient-linear-left-right {
    //   background: $bgPrimary;
    //   background-image: url("../img/rauschen-fein-blau.png"), -webkit-linear-gradient(0deg, $bgPrimary, #56d3fc);
    //   background-image: url("../img/rauschen-fein-blau.png"), linear-gradient(90deg, $bgPrimary, #56d3fc);
    //
    // }
    //
    // &_bg-shapes-one {
    //   @include css-shape-base;
    //
    //   &:before {
    //     @include css-shape($light-gray);
    //   }
    // }
    //
    // &_bg-shapes-two {
    //   @include css-shape-base;
    //
    //   &:before {
    //     @include css-shape($medium-gray);
    //   }
    // }

    &_position-minus {
        @include breakpoint(medium) {
            margin-top:-30px;
        }
        @include breakpoint(large) {
            margin-top:-80px;
        }
    }
}