.off-canvas {
    padding-top: $header-height-small;
    @include breakpoint(medium) {
        padding-top: $header-height-medium;
    }
    @include breakpoint(large) {
        padding-top: $header-height-large;
    }
    @include breakpoint(xlarge) {
        display: none;
    }

    .accordion-menu {
        li {
            & > a {
                padding: rem-calc(15px) 0 rem-calc(15px) 15px;
            }

            &.active > a {
                background: inherit;
                font-weight: $global-weight-bold;
            }

            .submenu-toggle {
                display: block;
                width: 40px;
                height: 48px;
                background: transparent;

                &:before {
                    font-family: $icon-font-family;
                    //@extend .icon-chevron-right;
                    color: $dark-gray;
                    font-size: rem-calc(16px);
                }

                &[aria-expanded="true"] {
                    // background: $medium-gray;
                    a {
                        // background: $medium-gray;
                    }

                    //&:before {
                    //    @extend .icon-chevron-down;
                    //}
                }

                &:after {
                    display: none;
                }
            }

            .submenu {
                margin: 0;

                li {
                    background: $light-gray;

                    a {
                        // font-weight: normal;
                        color: $dark-gray;
                        // font-size: rem-calc(18px);
                        padding: rem-calc(15px) 0 rem-calc(15px) 35px;
                        // &.active,
                        // &:hover {
                        //     background: $white;
                        // }
                    }

                    .submenu {
                        margin: 0;

                        li {
                            background: $medium-gray;

                            a {
                                // font-weight: normal;
                                color: $body-font-color;
                                font-size: 12px;
                                // font-size: rem-calc(18px);
                                padding: rem-calc(15px) 0 rem-calc(15px) 35px;
                                // &.active,
                                // &:hover {
                                //     background: $white;
                                // }
                            }
                        }
                    }
                }
            }
        }
    }

    .content {
        padding: 15px;

        p {
            a:not([class*="btn"]) {
                color: $black;
                font-family: $header-font-family;
                font-size: 24px;
            }
        }

        .language-selector {
            position: relative;
            top: auto;
            left: auto;

            .language-list {
                display: inline;

                li a {
                    color: $black;
                }

                li a:hover {
                    color: $dark-gray;
                }

                li.active a {

                    color: $dark-gray;

                    &:hover {
                        color: $dark-gray;
                    }
                }
            }
        }
    }
}

.js-off-canvas-overlay {
    background: rgba(94, 106, 113, 0.5);
    @include breakpoint(large) {
        display: none !important;
    }
}

.position-left {
    width: 100%;
    height: auto;
    transform: translateX(-100%);
    overflow: auto;
    max-height: 100%;
    @include breakpoint(medium) {
        max-width: 320px;
        transform: translateX(-320px);
    }
}
