.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  //z-index: -20;
  z-index: #{map-get($zIndex, 'fullscreen-bg')};
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;

  }

}
