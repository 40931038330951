#sidebar ul.vertical.menu {
    margin: 1rem 0;

    a {
        padding: 0.5rem 0 0.5rem 0;
    }

    li.is-active {
        // background: $light-primary;
        // border:1px solid  $light-primary;
        a {
            color: $primary-color;
            font-weight: $global-weight-bold;
            background: none;
        }

        li {
            a {
                font-weight: $global-weight-medium;
            }
            &.is-active {
                // background: scale-color($light-primary, $lightness: +30%);
                a {
                    color: $primary-color;
                    font-weight: $global-weight-bold;
                    background: none;
                }
            }
        }
    }

    ul {
        margin: 0;
    }

    &.nested {
        margin-left:1rem;
    }
}
