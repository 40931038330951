// File: _textpic.scss
.text_text {
    //h2:first-child,
    //h3:first-child,
    //h4:first-child {
    //    margin-bottom: 2rem;
    //}
    //h2 + p,
    //h3 + p,
    //h4 + p {
    //    margin-top: 2rem;
    //}
    //p + p {
    //    margin-top: 2rem;
    //}
    //p + h5 {
    //    margin-top: 2rem;
    //}
    //.subheader + h1,
    //.subheader + h2,
    //.subheader + h3 {
    //    margin-top: 2rem;
    //}
    h1:first-child {
        margin-bottom: 0;
    }
    h1 + p {
        font-size:rem-calc(15px);
        line-height:rem-calc(16px);
        @include breakpoint(medium) {
            font-size:rem-calc(20px);
            line-height:rem-calc(22px);
        }
        @include breakpoint(large) {
            font-size:rem-calc(40px);
            line-height:rem-calc(45px);
        }
    }

    a {
        overflow-wrap: break-word;
    }
}

.textpic_block {
    @include clearfix;

    ol,
    ul {
        display: inline-block;
    }
}

.textpic_image {
    display: block;
    width: 100%;
    margin: 0;
}

.textpic_text {
    &.textpic_bg_image {
        position:relative;
        padding-top: 50px;
        padding-bottom:0;
        .text_text {
            position: relative;
            z-index:1;
            @include breakpoint(medium) {
                h2 {
                    width:80%;
                }
            }
            @include breakpoint(large) {
                h2 {
                    width:66.67%;
                }
            }
        }
        .textpic_bg {
            position: absolute;
            z-index:0;
            right: 0;
            left: 0;
            bottom: 40px;
            max-height: 160px;
            img {
                max-width:100%;
                vertical-align: middle;
            }
            @include breakpoint(medium) {
                left: auto;
                bottom: auto;
                width: 50%;
                top: 50px;
                right: -50px;
                max-height: unset;
            }
            @include breakpoint(large) {
                right:-150px;
            }
        }


        @include breakpoint(large) {
            padding-top: 70px;
            padding-bottom:60px;
        }
    }
}

.textpic_images {
    width: 100%;
    @include bottom-margin(15px, 24px, 39px);

    img.img-border {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 1rem;
        border: solid 4px $medium-gray;
        border-radius: 0;
        box-shadow: 0 0 0 1px rgba($medium-gray, 0.2);
        line-height: 0;
    }

    &-no-bottom-indent {
        margin-bottom: 0;
    }

    &_align-left {
        float: left;
        margin-top: $space-xs/2;
        margin-right: $space-xs;
        padding: 0 $space-xs 0 0;
    }

    &_align-right {
        float: right;
        margin-top: $space-xs/2;
        margin-left: $space-xs;
        padding: 0 0 0 $space-xs;
    }

    &_caption {
        padding: $space-xs/2 0 0;
        color: $medium-gray;
    }

    .row {
        margin-bottom: -$space-s;
    }
}
$column-count: 12;
@for $i from 1 through $column-count {
    .textpic_block.small-up-#{$i} {
        & > .textpic_images {
            width: (100 / $i) * 1%;
        }
    }
    @include breakpoint(medium) {
        .textpic_block.medium-up-#{$i} {
            & > .textpic_images {
                width: (100 / $i) * 1%;
            }
        }
    }
    @include breakpoint(large) {
        .textpic_block.large-up-#{$i} {
            & > .textpic_images {
                width: (100 / $i) * 1%;
            }
        }
    }
}

.gallery-grid {
    .textpic_images {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .textpic_image,
        img {
            width: auto;
            height: auto;
            margin: auto;
        }
    }
}

.gallery-orgwidth {
    .row {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .column {
            max-width: 1000px;
            flex: 0 0 auto;

            .textpic_image {
                width: auto;
                display: inline-block;
            }
        }
    }

    &__centered {
        .row {
            justify-content: center;
        }
    }
}

.textpic_flex {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    & > * {
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 0;
        display: block;
        width: 100%;
    }

    &-above,
    &-above-left {
        flex-direction: column;

        .textpic_images {
            width: 48px;
            height: 48px;
            margin-bottom: 24px;

            img {
                width: 100%;
            }
            @include breakpoint(large) {
                width: 64px;
                height: 64px;
                margin-bottom: 30px;
            }
        }
    }

    &-above-left {
        align-items: flex-start;
    }

    &-above-circle {
        flex-direction: column;

        .textpic_images {
            width: 80px;
            height: 80px;
            border-radius: 100%;
            margin: 0 auto;
            text-align: center;
            vertical-align: middle;
            background-color: #B9CE37;
            margin-bottom:12px;

            img {
                padding-top: 10%;
                width: 80%;
            }
            @include breakpoint(large) {
                width: 100px;
                height: 100px;
            }
        }
    }

    &-left,
    &-right {
        flex-direction: row;
        align-items: flex-start;

        .textpic_images {
            width: 48px;
            @include breakpoint(large) {
                width: 64px;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        .textpic_text {
            padding: 0 0 0 $space-s;
            flex-shrink: 1;
            @include breakpoint(large) {
                padding: 0 0 0 $space-m;
            }
        }
    }

    &-right {
        flex-direction: row-reverse;

        .textpic_text {
            padding: 0 $space-s 0 0;
            flex-shrink: 1;
            @include breakpoint(large) {
                padding: 0 $space-m 0 0;
            }
        }
    }
}

.textpic_expanding_image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;

    img {
        opacity: 1;
        @include breakpoint(medium) {
            opacity: 0;
        }
    }
    @include breakpoint(small only) {
        img {
            display: block !important;
        }
    }
}

.textpic_expanding_flexwrap {
    height: 100%;
    @include breakpoint(medium) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .textpic_expanding_text {
        padding: 2rem 1rem 1rem 1rem;
        @include breakpoint(medium) {
            width: 100%;
            padding: 2rem;
        }
        @include breakpoint(large) {
            padding: 4rem;
            max-width: $global-width/2;
        }
    }

    &.text-flex-start {
        @include breakpoint(medium) {
            justify-content: flex-start;
        }
    }
}

.textpic_hover_image {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;

    .textpic_images {
        margin-bottom: 0;
    }

    .textpic_text_wrap {
        @include panel-padding(20px, 25px, 30px);
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: all 0.3s ease 0s;
        width: 100%;
        height: 100%;
        //z-index: 1;
        z-index: #{map-get($zIndex, 'textpic_text_wrap')};
        display: table;

        .textpic_text {
            display: table-cell;
            vertical-align: bottom;
            transform: none;
            position: relative;
        }
    }

    &_layout_1 {
        .textpic_image {
            background-color: $dark-gray;
        }

        .textpic_text_wrap {
            @include dark-typography;
            .textpic_text {
                bottom: -15px;
            }
        }

        &:hover {
            .textpic_image {
                transition: all 0.3s ease 0s;
            }

            img {
                opacity: 0.15;
                transform: scale(1.1, 1.1);
                transition: all 0.3s ease 0s;
            }

            .textpic_text_wrap {
                opacity: 1;
                transition: all 0.3s ease 0s;
            }

            .textpic_text {
                bottom: 0;
                transition: all 0.3s ease 0s;
            }
        }
    }

    &_layout_2 {
        .textpic_image {
            background-color: $dark-gray;
        }

        .textpic_text_wrap {
            @include dark-typography;

            .textpic_text {
                bottom: -15px;
                vertical-align: middle;
            }
        }

        &:hover {
            .textpic_image {
                transition: all 0.3s ease 0s;
            }

            img {
                opacity: 0;
                transition: all 0.3s ease 0s;
            }

            .textpic_text_wrap {
                opacity: 1;
                transition: all 0.3s ease 0s;
            }

            .textpic_text {
                bottom: 0;
                transition: all 0.3s ease 0s;
            }
        }
    }

    &_layout_3 {
        img {
            position: relative;
            top: 0;
            transition: all 0.3s ease 0s;
        }

        .textpic_text_wrap {
            bottom: -130px;
            height: auto;
            transition: all 0.3s ease 0s;

            .textpic_text {
                vertical-align: middle;
            }
        }

        &:hover {
            img {
                top: -130px;
            }

            .textpic_text_wrap {
                bottom: 0;
                opacity: 1;
            }
        }
    }

    &_layout_4 {
        .textpic_image {
            background-color: $dark-gray;
        }

        .textpic_text_wrap {
            padding: 0px;
            transition: all 0.3s ease 0s;
            .textpic_text {
                @include panel-padding(20px, 25px, 30px);
                background: $white;
                vertical-align: middle;
            }
        }

        &:hover {
            .textpic_image {
                transition: all 0.3s ease 0s;
            }

            .textpic_text_wrap {
               opacity: 1;
               padding:25px;
            }

        }
    }
}
