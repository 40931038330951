$height-icon: 14px;
$width-line: 20px;
$height-line: 2px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

.hamburger {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 0 0 20px;
  width: 66px;
  height: $header-height-small;
  //z-index: 40;
  z-index: #{map-get($zIndex, 'hamburger')};
  margin: 0;
  box-sizing: border-box;
  display: block;
  transition: all 0.2s ease;
  background:transparent;
  @include breakpoint(medium) {
    right:60px;
    height: $header-height-medium;
    width: 76px;
  }
  @include breakpoint(large) {
    right: 60px;
  }

  @include breakpoint(xlarge) {
    display: none !important;
  }

  &__line {
    display: block;
    background: #102E48;
    width: $width-line;
    height: $height-line;
    position: relative;
    left: 0;
    margin: 5px 0;
    border-radius: ($height-line / 2);
    transition: all $transition-time;
    -webkit-transition: all $transition-time;
    -moz-transition: all $transition-time;
  }

  &[aria-expanded="true"] {

    .hamburger__line {
      width: 28px;
      background: #102E48;
      left: -2px;
    }

    .hamburger_line-1 {
      transform: translateY($translateY) translateX($translateX) rotate($rotation);
      -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
      -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
    }
    .hamburger_line-2 {
      opacity: 0;
    }
    .hamburger_line-3 {
      transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
    }
  }
  &:focus,
  &:active,
  &:hover {
    background:transparent;
    outline:unset;
  }
}
