.tx-kesearch-pi1 {
    form {
        border: 0 none;
        margin: 0 0 1rem;

        .tx-kesearch-main {
            margin-bottom: 1rem;
        }

        #ke_search_sword {
            padding: rem-calc(24);
            margin-bottom: 1rem;
        }

        .tx-kesearch-filters {
            margin-bottom: 1rem;

            .columns {
                box-sizing: border-box;
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;

                & > * {
                    flex-shrink: 1;
                    flex-grow: 1;
                    min-width: 0;
                    margin-right: 1rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .list {
                .head {
                    display: block;
                    border: 1px solid #CFCFCF;
                    padding: 5px;
                }

                ul {
                    padding: 10px;
                    margin: 0;
                    border: 1px solid #cfcfcf;
                    border-top: 0 none;
                    list-style-type: none;
                }

                li {
                    display: block;
                    list-style-type: none;
                    margin: 0.7rem 0;

                    label {
                        color: $body-font-color;
                        display: block;
                        margin: 0 0 4px;
                        line-height: 1;
                        font-weight: $global-weight-bold;
                    }

                    input[type="checkbox"] {
                        float: left;
                        margin: 0 5px 0 0;

                        &:disabled + label {
                            color: $medium-gray;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }

        a.resetButton {
            // float: left;
            @extend .btn;
            @extend .btn__bg-secondary;
        }

        span.submitbutt input {
            @extend .btn;
        }
    }
}

.tx-kesearch-pi1 {
    #kesearch_num_results {
        font-weight: $global-weight-bold;
        margin-bottom:1rem;
    }

    #kesearch_results {
        .result-list-item {
            border-bottom: 1px solid $light-gray;
            @include clearfix;
            padding: 2rem 0;

            .result-number {}

            .result-number,
            .result-title {
                font-size: rem-calc(18px);
                @include breakpoint(medium) {
                    font-size: rem-calc(21px);
                }
            }

            .result-title {}

            .scoreBar {
                margin-top: 10px;
                outline: 1px solid $medium-gray;
                background-color: $light-gray;
                display: block;
                float: right;
                height: 10px;
                width: 48px;

                .score {
                    background-color: $secondary-color;
                    // border: 1px solid  $medium-gray;
                    display: block;
                    height: 10px;
                    // margin: -1px 0 0 -1px;
                }

                &:after {
                    @include clearfix;
                }
            }

            .result-teaser {
                .high {
                    font-weight: $global-weight-bold;
                }
            }
        }

        .result-list-item-flex {
            border-bottom: 1px solid $light-gray;
            padding: 2rem 0;
            @include clearfix;

            &:first-child {
                border-top: 1px solid $light-gray;
            }

            &__icon {
                float: left;
                margin: 7px 10px 30px 0;
                @include breakpoint(small only) {
                    margin-bottom: 0px;
                    display: block;
                    width: 100%;
                }
                @include breakpoint(medium) {
                    margin-bottom: 7px;
                }
            }

            &__head {
                padding: 0 30px 0 0;
                position: relative;
            }

            &__number,
            &__title {
                font-size: rem-calc(18px);
                @include breakpoint(medium) {
                    font-size: rem-calc(21px);
                }
            }

            &__number {
                padding-right: rem-calc(2px);
            }

            &__percent {
                position: absolute;
                right: 0;
                top: 0;
            }

            &__info {
                font-size: rem-calc(12px);
                padding: 0 0 rem-calc(10px);
                @include breakpoint(medium) {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    align-content: flex-start;
                }
            }

            &__url {}

            &__date {}
            &__teaser {
                .high {
                    font-weight: $global-weight-bold;
                }
            }
        }
    }
}

.pagination-wraper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    padding: rem-calc($space-s) 0;

    & > * {
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 0;
        display: block;
    }

    .pages_total {
        .result_txt {
            font-weight: $global-weight-bold;
        }
    }

}
// .tx-kesearch-pi1 {
//     font-family: Sans-Serif;
// }
/* Clearer */
// .tx-kesearch-pi1 .clearer {
//     display: block;
//     height: 1px;
//     line-height: 1px;
//     clear: both;
// }
/* formElements */
// .tx-kesearch-pi1 form fieldset {
//     margin: 0;
//     padding: 0;
// }
//
// .tx-kesearch-pi1 .kesearch_searchbox {
//     border: none;
// }
// .tx-kesearch-pi1 input[type="text"],
// .tx-kesearch-pi1 select,
// .tx-kesearch-pi1 textarea {
//     padding: 0.3em 0.3em 0.3em 0.4em;
//     background: white;
//     border: 1px solid #bbbbbb;
//     -webkit-border-radius: 2px;
//     -moz-border-radius: 2px;
//     border-radius: 2px;
//     color: #2b2b2b;
// }
// .tx-kesearch-pi1 input[type="text"]:focus,
// .tx-kesearch-pi1 input[type="text"]:hover,
// .tx-kesearch-pi1 select:focus,
// .tx-kesearch-pi1 select:hover,
// .tx-kesearch-pi1 textarea:focus,
// .tx-kesearch-pi1 textarea:hover {
//     box-shadow: 0 0 3px #bbbbbb;
//     border: 1px solid #b2b2b2;
//     color: #000;
// }
/* Buttons */
// a.kesGreyButt,
// input.kesGreyButt,
// span.kesGreyButt,
// span.resetbutt a,
// span.submitbutt input {
//     font-size: 1em;
//     font-family: Sans-Serif;
//     padding: 5px 10px;
//     margin: 0 10px 10px 0;
//     display: block;
//     cursor: pointer;
//     min-width: 70px;
//     max-width: 140px;
//     -moz-user-select: none;
//     background-color: #f5f5f5;
//     background-image: -moz-linear-gradient(center top , #f6f6f6, #f0f0f0);
//     border: 1px solid rgba(0, 0, 0, 0.1);
//     border-radius: 2px;
//     color: #444;
//     cursor: default;
//     text-align: center;
//     text-decoration: none;
// }
// input.kesGreyButt,
// span.kesGreyButt,
// span.submitbutt input {}
/* fix firefox button size (line-height is not working in firefox submit button)*/
// input::-moz-focus-inner {
//     border: 0;
//     padding: 0;
// }
// input.kesGreyButt:hover,
// span.kesGreyButt:hover,
// span.resetbutt a:hover,
// span.submitbutt input:hover {
//     color: #000;
//     text-decoration: none;
//     cursor: pointer;
//     background: #d1d1d1;
//     background-color: #f8f8f8;
//     background-image: -moz-linear-gradient(center top , #f8f8f8, #f1f1f1);
//     border: 1px solid #c6c6c6;
//     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
//     color: #222;
// }
// span.kesGreyButt a {
//     text-decoration: none;
//     color: #444;
//     text-shadow: 0 1px 0 #ffffff;
// }
//
// span.kesGreyButt a:hover {
//     text-decoration: none;
//     color: #000;
//     text-shadow: 0 -1px 0 #ffffff;
// }
/* buttFixes */
// #kesearch_filters .list li.checkboxButton,
// #kesearch_filters .list li.checkboxButtonSubmit {
//     text-decoration: none !important;
//     padding-bottom: 5px;
// }
//
// #kesearch_filters .list li.checkboxButtonSubmit {
//     margin-bottom: 1px;
// }
//
// #kesearch_filters .list li.checkboxButtonSubmit {
//     padding-bottom: 0;
// }
/* SearchBox */
// div.kesearchbox {
//     position: relative;
//     height: 35px;
//     margin: 0 0 10px;
// }
// #kesearch_submit:focus,
// #kesearch_submit:hover {
//     box-shadow: 0 0 0 white;
//     border: 0 none;
//     cursor: pointer;
// }
/* Filters */
// #kesearch_filters div.textlinks {
//     margin: 0 0 20px;
// }
//
// #kesearch_filters select {
//     margin: 0 0 10px;
// }
// #kesearch_filters .list {
//     margin-bottom: 10px;
// }
// #kesearch_filters .list ul.expanded {
//     display: block;
// }
//
// #kesearch_filters .list ul.closed {
//     display: none;
// }
// #kesearch_filters .list li {
//
// }
// #kesearch_filters .list li a {
//     color: #444;
// }
//
// #kesearch_filters .list li a:hover,
// #kesearch_filters .list li:hover {
//     text-decoration: none;
//     color: #444;
//     cursor: pointer;
// }
//
// #kesearch_filters .list li.selected {
//     font-weight: bold;
// }
//
// #kesearch_filters .list li.level0,
// #kesearch_filters .list li.parent0 {
//     margin-left: 14px;
// }
//
// #kesearch_filters .list li.level1,
// #kesearch_filters .list li.parent1 {
//     margin-left: 21px;
// }
//
// #kesearch_filters .list li.level2,
// #kesearch_filters .list li.parent2 {
//     margin-left: 28px;
// }
//
// #kesearch_filters .list li.level3,
// #kesearch_filters .list li.parent3 {
//     margin-left: 35px;
// }
//
// #kesearch_filters .list li.parent0,
// #kesearch_filters .list li.parent1,
// #kesearch_filters .list li.parent2,
// #kesearch_filters .list li.parent3 {
//     font-weight: bold;
//     list-style-position: outside;
// }
/* Checkboxes */
// #kesearch_filters .list ul.checkboxList {
//     padding-bottom: 1px;
// }
// #kesearch_filters .list li.optionCheckBox {
//
// }
// #kesearch_filters .list li.optionCheckBox input[type="checkbox"] {
//     float: left;
//     margin: 0 5px 0 0;
// }
//
// #kesearch_filters .list li.clearer {
//     font-size: 0;
//     line-height: 0;
//     float: none;
//     margin: 0 !important;
// }
//
// #kesearch_filters .list li.region {
//     font-weight: bold;
//     margin-top: 5px;
// }
//
// #kesearch_filters .list li.noclick {
//     cursor: default !important;
// }
//
// #kesearch_filters .list li.country {
//     margin-left: 7px;
// }
/* SPECIAL CSS CLASS */
// #kesearch_filters .small {
//     display: block;
//     height: 65px;
//     max-height: 65px;
//     overflow-y: auto;
//     overflow-x: hidden;
// }
//
// #kesearch_filters .larger {
//     display: block;
//     height: 130px;
//     max-height: 130px;
//     overflow-y: auto;
//     overflow-x: hidden;
// }
/* Results */
// #kesearch_num_results {
//     color: #898989;
//     float: left;
// }
// #kesearch_results .result-list-item {
//     clear: both;
//     margin: 20px 0 30px;
//     padding: 0;
// }
//
// #kesearch_results .result-list-item .result-number,
// #kesearch_results .result-list-item .result-title {
//     font-size: 1.2em;
// }
// #kesearch_results {
//     clear: both;
//     margin-top: 20px;
// }
//
// #kesearch_results .result-list-item .result-teaser {
//     color: #707070;
// }
//
// .tx-kesearch-pi1 #kesearch_results .result-list-item-type-page {}
//
// .tx-kesearch-pi1 #kesearch_results .result-list-item-type-tt_news {}
//
// .tx-kesearch-pi1 #kesearch_results .teaser_icon img {
//     float: left;
//     margin-top: 2px;
//     margin-right: 5px;
// }
//
// .tx-kesearch-pi1 #kesearch_results .result-list-item .hit {
//     color: red;
//     font-weight: bold;
// }
//
// .tx-kesearch-pi1 #kesearch_results .result-list-item .add-info {}
// .tx-kesearch-pi1 #kesearch_results .result-list-item .scoreBar {
//     border: 1px solid #D3D3D3;
//     display: block;
//     float: right;
//     height: 10px;
//     width: 48px;
// }
//
// .tx-kesearch-pi1 #kesearch_results .result-list-item .scoreBar .score {
//     background-color: #D3D3D3;
//     border: 1px solid #898989;
//     display: block;
//     height: 10px;
//     margin: -1px 0 0 -1px;
// }
/* Query time */
// .tx-kesearch-pi1 #kesearch_query_time {
//     font-style: italic;
//     text-align: center;
//     font-size: 80%;
// }
/* Searchbox */
// .tx-kesearch-pi1 .searchbox {
//     margin-bottom: 5px;
// }
//
// .tx-kesearch-pi1 .searchbox input {
//     margin-left: 0;
//     width: 145px;
// }
//
// .tx-kesearch-pi1 .searchbox input.submit {
//     width: 18px;
//     height: 18px;
//     border: none;
//     background: none;
//     vertical-align: middle;
// }
//
// .tx-kesearch-pi1 form.static .searchbox input.submit {
//     margin-top: -16px;
// }
//
// .tx-kesearch-pi1 form.ajax .searchbox input.submit {
//     margin-top: -4px;
// }
//
// .tx-kesearch-pi1 .searchbox .search_input {
//     float: left;
//     margin-right: 5px;
// }
// /* General Message */
//
// .tx-kesearch-pi1 .general-message {
//     padding: 5px;
// }
//
// .tx-kesearch-pi1 .general-message .image {
//     float: left;
//     vertical-align: middle;
//     margin-right: 10px;
// }
// /* Pagebrowser */
//
// .kesearch_pagebrowser ul {
//     display: inline;
//     margin: 0;
//     padding: 0;
//     -webkit-margin-before: 0;
//     -webkit-margin-after: 0;
//     -webkit-margin-start: 0;
//     -webkit-margin-end: 0;
//     -webkit-padding-start: 0;
// }
//
// .kesearch_pagebrowser ul li {
//     list-style-type: none;
//     display: inline;
//     margin: 0;
// }
// #kesearch_pagebrowser_bottom,
// #kesearch_pagebrowser_top {
//     text-align: center;
//     word-wrap: break-word;
//     color: #707070;
// }
// #kesearch_pagebrowser_bottom .pages_total,
// #kesearch_pagebrowser_top .pages_total {
//     padding: 8px 0;
//     background: #fff;
//     margin-bottom: 15px;
// }
// #kesearch_pagebrowser_top .pages_total {
//     border-bottom: 1px solid #cccccc;
// }
//
// #kesearch_pagebrowser_bottom .pages_total {
//     border-top: 1px solid #cccccc;
// }
// #kesearch_pagebrowser_bottom div.result_txt,
// #kesearch_pagebrowser_top div.result_txt {
//     margin: 0 0 5px;
// }
//
// #kesearch_pagebrowser_bottom .kesearch_pagebrowser,
// #kesearch_pagebrowser_top .kesearch_pagebrowser {
//     margin: 0 auto;
// }
//
// #kesearch_pagebrowser_bottom a,
// #kesearch_pagebrowser_top a {
//     padding: 3px;
// }
//
// #kesearch_pagebrowser_bottom a:hover,
// #kesearch_pagebrowser_top a:hover {
//     cursor: pointer;
// }
//
// #kesearch_pagebrowser_bottom a.current,
// #kesearch_pagebrowser_top a.current {
//     font-weight: bold;
//     color: #000;
//     text-decoration: none;
// }
//
// #kesearch_pagebrowser_bottom .browser,
// #kesearch_pagebrowser_top .browser {
//     border: 1px solid;
// }
// /* Ordering */
//
// .tx-kesearch-pi1 #kesearch_ordering span.down {
//     display: inline-block;
//     width: 0;
//     height: 0;
//     border-color: #FF0000 transparent;
//     border-style: solid solid none;
//     border-width: 4px 4px 0;
// }
//
// .tx-kesearch-pi1 #kesearch_ordering span.up {
//     display: inline-block;
//     width: 0;
//     height: 0;
//     border-color: transparent transparent #FF0000;
//     border-style: none solid solid;
//     border-width: 0 4px 4px;
// }
//
// .tx-kesearch-pi1 #kesearch_ordering div.ordering {
//     float: right;
//     max-width: 300px;
// }
//
// .tx-kesearch-pi1 #kesearch_ordering ul {
//     display: inline-block;
//     height: 20px;
//     list-style-type: none;
//     margin: 0 0 10px;
//     padding: 0;
// }
//
// .tx-kesearch-pi1 #kesearch_ordering ul li {
//     float: left;
//     margin-right: 10px;
// }
//
// .tx-kesearch-pi1 #kesearch_ordering div.clearer {
//     clear: left;
// }
// /* needed for autocomplete */
//
// ul.ui-autocomplete li a {
//     font-size: 12px;
// }
