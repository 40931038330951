.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  //z-index: -1;
  z-index: #{map-get($zIndex, 'inputfile')};
}

.inputfile + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;

  background: $white;
  position: relative;
  border: 1px solid $medium-gray;
  display: block;
  width: 100%;
  height: rem-calc(40px);
  padding:0 100px 0 15px;
  font-family:$body-font-family;
  font-size:rem-calc(14px);
  color:  $body-font-color;
  font-weight: normal;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgba(26, 33, 36, 0.1);

}

.no-js .inputfile + label {
  display: none;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}


.inputfile:focus + label,
.inputfile.has-focus + label,
.inputfile + label:hover {
  box-shadow: 0 1px 2px rgba(90, 90, 90, 0.1) inset;
}

.inputfile + label strong {
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;

  width: 88px;
  height: 28px;
  text-align: center;
  line-height:28px;
  vertical-align: middle;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
  font-size: rem-calc(12px);
  color: $white;
  text-transform: uppercase;
  background: $dark-gray;
}

.inputfile + label span {
  width: 100%;
  min-height: 39px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  line-height:39px;
  font-family:$body-font-family;
  font-size: rem-calc(14px);
  color:  $body-font-color;
}
