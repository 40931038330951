.contenttable {
  @extend table;

  &-hover {
    thead tr {
      &:hover {
        background-color: $table-head-row-hover;
      }
    }

    tfoot tr {
      &:hover {
        background-color: $table-foot-row-hover;
      }
    }

    tbody tr {
      &:hover {
        background-color: $table-row-hover;
      }
    }
  }

  &-unstriped {
    tbody tr, tbody tr:nth-child(2n) {
      border-bottom: 0;
      border-bottom: $table-border;
      background: $white;
    }
  }

  &-stack {
    @include breakpoint(medium down) {
      thead {
        display: none;
      }

      tfoot {
        display: none;
      }

      tr,
      th,
      td {
        display: block;
      }

      td {
        border-top: 0;
      }

    }
  }

}

