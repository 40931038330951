
@mixin dark-typography() {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $header-dark-color;
    }

    ol,
    p,
    ul {
      color: $body-dark-color;
    }

    ul.primary-bullets li:before {
      background: $anchor-dark-color;
    }

    ul.primary-bullets li ul li:before {
      background: $anchor-dark-color;
    }

    ol.primary-numbers li:before {
      background: $anchor-dark-color;
    }

    a:not(.btn) {
      color: $anchor-dark-color;

      &:hover {
        color: $anchor-dark-color-hover;
      }
    }
}
main {
  div[class^="section"] {
    &.dark {
      @include dark-typography;
      div[class^="panel"] {
        &:not(.dark) {
          &:not([class*="transparent"]) {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: $header-color;
            }

            ol,
            p,
            ul {
              color: $body-font-color;
            }

            ul.primary-bullets li:before {
              background: get-color(primary);
            }

            ul.primary-bullets li ul li:before {
              background: get-color(primary);
            }

            ol.primary-numbers li:before {
              background: get-color(primary);
            }

            a:not(.btn) {
              color: $anchor-color;

              &:hover {
                color: $anchor-color-hover;
              }
            }
          }
        }
      }
    }
  }

  div.section_bg-secondary.dark {
    h1{
      &.separator {
          &::after {
              border-bottom-color:$white;
          }
      }
    }
}

  div[class^="panel"] {
    &.dark {
       @include dark-typography;
    }
  }

  div.block_transparent {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }

    ol,
    p,
    ul {
      color: inherit;
    }

    ul.primary-bullets li:before {
      background: inherit;
    }

    ul.primary-bullets li ul li:before {
      background: inherit;
    }

    ol.primary-numbers li:before {
      background: inherit;
    }

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }
}
