$proportion-4-to-3: (100 / 75);

$proportional-4-to-3: calc(100% * (100 / 75));
$proportional-4-to-3-times-two: calc(100% * (100 / 75) * 2);

.isotope-grid {
  display: flex;
  flex-wrap: wrap;
  &__sizer, &__item {

    width: 25%;
    @include breakpoint(medium only) {
      width: 50%;
    }
    @include breakpoint(small only) {
      width: 100% !important;
    }
    &:after { // Sizing (padding-top 100% is 1:1 aspect ratio)
      display: block;
      content: '';
      padding-top: calc(100% * #{$proportion-4-to-3});
      @include breakpoint(small only) {
        padding-top: 100% !important;
      }
    }
  }
}

.isotope-grid__item { // .isotope-grid__item
  position: relative;
  //3/4 witdth/height ratio 75%/100%
  //Modifiers
  &--width2 {
    width: 50%;
    &:after {
      padding-top: calc(100% * #{$proportion-4-to-3} / 2);
    }
    @include breakpoint(small down) {
      width: 100%;
    }
    //flex-grow: 2;
  }
  &--height2 {
    &:after {
      padding-top: calc(100% * #{$proportion-4-to-3} * 2);
    }
  }
  //Element
  &__inner-wrap {
    background-color: get-color(primary);
    background-position: center center;
    background-size: cover;
    position: absolute;
    $gutter: .1rem;
    top: $gutter;
    right: $gutter;
    left: $gutter;
    bottom: $gutter;
  }
}
.isotope-grid__item--height2.isotope-grid__item--width2 {
  &:after {
    padding-top: calc(100% * #{$proportion-4-to-3});
  }
}

.isotope__filter-button-group{
    margin-bottom:1rem;
    @include breakpoint(small only) {
        display:none;
    }
    text-align: center;
    button.isotope {
        padding:0.8em 1em;
        font-weight: $global-weight-bold;
        color: $body-font-color;
        &.is-active{
            color: $primary-color;
        }
    }
}
.isotope__filter-dropdown-wrap{
    margin-bottom:1rem;
    display:block;
    @include breakpoint(medium) {
        display:none !important;
    }
}
