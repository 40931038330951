@include breakpoint(large) {
  .is-dropdown-submenu {
    z-index: 5;
    z-index: #{map-get($zIndex, 'is-dropdown-submenu')};
  }

  .magellan_item {
    position: static;

    &__submenu { //.magellan_item__submenu
      position: fixed;
      width: 100%;
      top: auto !important;
      //z-index: 1;
      z-index: #{map-get($zIndex, 'magellan_item__submenu')};
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;

      > li {
        width: auto;
      }

      a.is-active {
        font-weight: bold;
      }

    }

    & > a:after {
      display: none !important;
    }

    a + .magellan_item__submenu {
      display: none !important;
    }

    a.active + .magellan_item__submenu {
      display: flex !important;
    }

  }

  &.is-active {
    a + .magellan_item__submenu {
      display: flex !important;
    }
  }

}

.sticky-dots {
  position: fixed;
  right: 10px;
  top: 50%;
  //z-index: 10;
  z-index: #{map-get($zIndex, 'sticky-dots')};
  transform: translate(0, -50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include breakpoint(xlarge) {
    right: 20px;
  }

  &__item {
    margin: 10px 0;
    @include breakpoint(medium) {
      margin: 12px 0;
    }
    @include breakpoint(xlarge) {
      margin: 15px 0;
    }

    a {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      text-indent: -9000px;
      border: 3px solid transparent;
      background: $medium-gray;

      @include breakpoint(xlarge) {
        width: 10px;
        height: 10px;
      }

      &.is-active {
        background: $dark-gray;
      }

    }

  }

}




