// Page with sidebars
@mixin fixed($width, $direction) {
  position: absolute;
  width: rem-calc($width) + $column-gutter;
  top: 0;
  @if $direction == left {
    left: 0;
  } @else {
    right: 0;
  }
}
@mixin fluid($width, $direction) {
  @if $direction == left {
    padding-right: rem-calc($width) + ($column-gutter * 1.5);
  } @else {
    padding-left: rem-calc($width) + ($column-gutter * 1.5);
  }
}

/* Common mixins */
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin grid-gutters($gg-small, $gg-medium, $gg-large) {
    margin-right: rem-calc(-$gg-small);
    margin-left: rem-calc(-$gg-small);

    .column, .columns {
      padding-right: rem-calc($gg-small);
      padding-left: rem-calc($gg-small);
    }
    .column-block {
      margin-bottom: rem-calc($gg-small*2);
    }

    @include breakpoint(medium) {
        margin-right: rem-calc(-$gg-medium);
        margin-left: rem-calc(-$gg-medium);
        .column, .columns {
          padding-right: rem-calc($gg-medium);
          padding-left: rem-calc($gg-medium);
        }
        .column-block {
          margin-bottom: rem-calc($gg-medium*2);
        }
    }
    @include breakpoint(large) {
        margin-right: rem-calc(-$gg-large);
        margin-left: rem-calc(-$gg-large);
        .column, .columns {
          padding-right: rem-calc($gg-large);
          padding-left: rem-calc($gg-large);
        }
        .column-block {
          margin-bottom: rem-calc($gg-large*2);
        }
    }
}

@mixin panel-padding($smallIndent, $mediumIndent, $largeIndent) {
  padding: rem-calc($smallIndent);
  @include breakpoint(medium) {
    padding: rem-calc($mediumIndent);
  }
  @include breakpoint(large) {
    padding: rem-calc($largeIndent);
  }
}

@mixin top-padding($smallIndent, $mediumIndent, $largeIndent) {
  padding-top: rem-calc($smallIndent);
  @include breakpoint(medium) {
    padding-top: rem-calc($mediumIndent);
  }
  @include breakpoint(large) {
    padding-top: rem-calc($largeIndent);
  }
}
@mixin bottom-padding($smallIndent, $mediumIndent, $largeIndent) {
  padding-bottom: rem-calc($smallIndent);
  @include breakpoint(medium) {
    padding-bottom: rem-calc($mediumIndent);
  }
  @include breakpoint(large) {
    padding-bottom: rem-calc($largeIndent);
  }
}

@mixin top-margin($smallIndent, $mediumIndent, $largeIndent) {
  margin-top: rem-calc($smallIndent);
  @include breakpoint(medium) {
    margin-top: rem-calc($mediumIndent);
  }
  @include breakpoint(large) {
    margin-top: rem-calc($largeIndent);
  }
}
@mixin bottom-margin($smallIndent, $mediumIndent, $largeIndent) {
  margin-bottom: rem-calc($smallIndent);
  @include breakpoint(medium) {
    margin-bottom: rem-calc($mediumIndent);
  }
  @include breakpoint(large) {
    margin-bottom: rem-calc($largeIndent);
  }
}


//section shapes
@mixin css-shape-base {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
@mixin css-shape ($bgcolor) {
  content: '';
  display: block;
  width: 1000px;
  height: 1000px;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: $bgcolor;
}

//ease animate
@mixin transition_ease ($animate) {
  -webkit-transition: $animate 0.5s ease;
  -moz-transition: $animate 0.5s ease;
  -ms-transition: $animate 0.5s ease;
  -o-transition: $animate 0.5s ease;
  transition: $animate 0.5s ease;
}

@mixin transition_ease-in ($animate) {
  -webkit-transition: $animate 0.3s ease-in;
  -moz-transition: $animate 0.3s ease-in;
  -ms-transition: $animate 0.3s ease-in;
  -o-transition: $animate 0.3s ease-in;
  transition: $animate 0.3s ease-in;
}