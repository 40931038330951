ul.ce-uploads {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li.ce-uploads-element {
        display: flex;
        margin-bottom: 1rem;
        flex-wrap: nowrap;

        .ce-uploads-element-icon {
            flex: 0 1 auto;
            padding-right: 1rem;
            padding-top: 8px;

         img {
                display: inline-block;
                max-width: 70px;
                @include breakpoint(medium) {
                  max-width: 100px;
                }
                border:1px solid $light-gray;
            }
        }

        .ce-uploads-element-metadata {
            flex: 0 1 auto;
            .ce-uploads-fileName{
                font-size:18px;
                display:block;
            }
            .ce-uploads-filesize{
               font-weight: $global-weight-bold;
            }
        }
    }
}

.file-icon {
    font-family: $body-font-family;
    font-weight: $global-weight-bold;
    display: inline-block;
    width: 30px;
    height: 40px;
    border-radius: 3px;
    background: #018fef;
    position: relative;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    @include breakpoint(medium) {
        width: 48px;
        height: 64px;
    }

    &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom-left-radius: 2px;
        border-width: 8px;
        border-style: solid;
        border-color: #fff #fff rgba(255,255,255,.35) rgba(255,255,255,.35);
    }

    &:after {
        display: block;
        content: attr(data-type);
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 11px;
        padding: 4px 6px;
        color: #fff;
        text-transform: lowercase;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        @include breakpoint(medium) {
            font-size: 14px;
        }
    }
}

.file-icon[data-type=rar],
.file-icon[data-type=zip] {
    background: #acacac;
}

.file-icon[data-type^=doc] {
    background: #307cf1;
}

.file-icon[data-type^=xls] {
    background: #0f9d58;
}

.file-icon[data-type^=ppt] {
    background: #d24726;
}

.file-icon[data-type=pdf] {
    background: #e13d34;
}

.file-icon[data-type=txt] {
    background: #5eb533;
}

.file-icon[data-type=flac],
.file-icon[data-type=m4a],
.file-icon[data-type=mp3],
.file-icon[data-type=wma] {
    background: #8e44ad;
}

.file-icon[data-type=avi],
.file-icon[data-type=mkv],
.file-icon[data-type=mov],
.file-icon[data-type=mp4],
.file-icon[data-type=wmv] {
    background: #7a3ce7;
}

.file-icon[data-type=bmp],
.file-icon[data-type=gif],
.file-icon[data-type=jpeg],
.file-icon[data-type=jpg],
.file-icon[data-type=png] {
    background: #f4b400;
}
