footer {
    max-width: 1920px;
    margin:0 auto;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#102e48+0,102e48+100&0+28,0+28,1+36 */
    background: linear-gradient(to bottom, rgba(16,46,72,0) 0%,rgba(16,46,72,0) 33%,rgba(16,46,72,1) 38%,rgba(16,46,72,1) 100%), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOTQuNDcyIiBoZWlnaHQ9IjIwMy43NzUiIHZpZXdCb3g9IjAgMCAzOTQuNDcyIDIwMy43NzUiPg0KICA8cGF0aCBpZD0iUGF0aF8xMDc5IiBkYXRhLW5hbWU9IlBhdGggMTA3OSIgZD0iTS0xNDc5LjQ4NywxMDc0Ljc2bDEuMDEsMTgyLjc0NiwzNDguMDY4LDIxLjAyOSw0NS4zOTQtMjEuNzczdi0xODJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTA4NS4wMTUgMTI3OC41MzUpIHJvdGF0ZSgxODApIiBmaWxsPSIjMTAyZTQ4Ii8+DQo8L3N2Zz4NCg==) top center no-repeat;
    background-size: 101% auto;
    min-height:140px;
    color:#fff;
    padding-top:24px;
    font-size:rem-calc(16px);
    line-height:rem-calc(21px);
    @include breakpoint(medium) {
        font-size:rem-calc(10px);
        line-height:rem-calc(12px);
        padding-top:0;
        min-height:44px;
        background: linear-gradient(to bottom, rgba(16,46,72,0) 0%,rgba(16,46,72,0) 15%,rgba(16,46,72,1) 20%,rgba(16,46,72,1) 100%), top center / 101% auto no-repeat url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOTE5LjI1NiIgaGVpZ2h0PSI5Ny40MDYiIHZpZXdCb3g9IjAgMCAxOTE5LjI1NiA5Ny40MDYiPg0KICA8cGF0aCBpZD0iUGF0aF8xMjY0IiBkYXRhLW5hbWU9IlBhdGggMTI2NCIgZD0iTS0xNDExNSwxMDg4Ni4xMzVoLTE5MTkuMjU2di04My4xbDE3NDMuMzUzLTE0LjMwNiwxNzUuOSwxNC4zMDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNjAzNC4yNTYgLTEwNzg4LjcyOSkiIGZpbGw9IiMxMDJlNDgiLz4NCjwvc3ZnPg0K);
    }
    @include breakpoint(large) {
        font-size:rem-calc(16px);
        line-height:rem-calc(21px);
    }
    .csc-menu {
        &.csc-menu-horizontal {
            justify-content: flex-start;
        }
    }

    .dark {
        h1, .h1,
        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5,
        h6, .h6 {
            color: $header-dark-color;
        }

        ol,
        p,
        ul {
            color: $body-dark-color;
        }

        a {
            color: $light-gray;

            &:hover {
                color: $white;
            }
        }
    }

    a {
        color:#fff;
    }
}

.scroll-top-arrow,.scroll-top-arrow:focus {
    color: $white;
    background-color: rgba($black, 0.5);
    line-height: 28px;
    display: none;
    height: 50px;
    width: 50px;
    padding: 0;
    position: fixed;
    right: 30px;
    bottom:30px;
    text-align: center;
    text-decoration: none;
    //z-index: 90;
    z-index: #{map-get($zIndex, 'scroll-top-arrow')};
    border-radius: 100%;
    &:hover {
        background-color: rgba($black, 1);
        color: $white;

    }

    i {
        line-height:50px;
        position: relative;
    }
}
