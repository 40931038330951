/**
  Font Face
  ......
  @font: Helvetica Neue
  ......
 */

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueblack.eot');
  src: url('../fonts/helvetica_neue/helveticaneueblack.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueblack.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueblack.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueblack.svg#Helvetica Neue Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueblackitalic.eot');
  src: url('../fonts/helvetica_neue/helveticaneueblackitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueblackitalic.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueblackitalic.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueblackitalic.svg#Helvetica Neue Black Italic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneuebold.eot');
  src: url('../fonts/helvetica_neue/helveticaneuebold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneuebold.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneuebold.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneuebold.svg#Helvetica Neue Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneuebolditalic.eot');
  src: url('../fonts/helvetica_neue/helveticaneuebolditalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneuebolditalic.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneuebolditalic.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneuebolditalic.svg#Helvetica Neue Bold Italic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueheavy.eot');
  src: url('../fonts/helvetica_neue/helveticaneueheavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueheavy.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueheavy.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueheavy.svg#Helvetica Neue Heavy') format('svg');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueheavyitalic.eot');
  src: url('../fonts/helvetica_neue/helveticaneueheavyitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueheavyitalic.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueheavyitalic.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueheavyitalic.svg#Helvetica Neue Heavy Italic') format('svg');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueitalic.eot');
  src: url('../fonts/helvetica_neue/helveticaneueitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueitalic.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueitalic.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueitalic.svg#Helvetica Neue Italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneuelight.eot');
  src: url('../fonts/helvetica_neue/helveticaneuelight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneuelight.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneuelight.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneuelight.svg#Helvetica Neue Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneuemedium.eot');
  src: url('../fonts/helvetica_neue/helveticaneuemedium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneuemedium.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneuemedium.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneuemedium.svg#Helvetica Neue Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneuemediumitalic.eot');
  src: url('../fonts/helvetica_neue/helveticaneuemediumitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneuemediumitalic.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneuemediumitalic.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneuemediumitalic.svg#Helvetica Neue Medium Italic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueroman.eot');
  src: url('../fonts/helvetica_neue/helveticaneueroman.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueroman.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueroman.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueroman.svg#Helvetica Neue Roman') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneuethin.eot');
  src: url('../fonts/helvetica_neue/helveticaneuethin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneuethin.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneuethin.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneuethin.svg#Helvetica Neue Thin') format('svg');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneuethinitalic.eot');
  src: url('../fonts/helvetica_neue/helveticaneuethinitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneuethinitalic.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneuethinitalic.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneuethinitalic.svg#Helvetica Neue Thin Italic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueultralight.eot');
  src: url('../fonts/helvetica_neue/helveticaneueultralight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueultralight.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueultralight.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueultralight.svg#Helvetica Neue Ultra Light') format('svg');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Helvetica Neue';
  src: url('../fonts/helvetica_neue/helveticaneueultralightitalic.eot');
  src: url('../fonts/helvetica_neue/helveticaneueultralightitalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/helvetica_neue/helveticaneueultralightitalic.woff2') format('woff2'),
  url('../fonts/helvetica_neue/helveticaneueultralightitalic.woff') format('woff'),
  url('../fonts/helvetica_neue/helveticaneueultralightitalic.svg#Helvetica Neue Ultra Light Italic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0-10FFFF;
}