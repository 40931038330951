div.ce-tabs.minimal {
    ul.tabs {
        border-width: 2px;
        border-bottom: 2px solid $light-gray;
        border-top: none;
        border-left: none;
        border-right: none;
        background: none;

        li.tabs-title {
            position: relative;

            a {
                text-transform: uppercase;
                color:$body-font-color;
            }
            a[aria-selected="true"] {
                background: none;
                color:get-color(primary);
            }

            a:after {
                background-color: get-color(primary);
                content: ' ';
                display: block;
                width: 0;
                height: 2px;
                position: absolute;
                bottom: -2px;
                left: 0;
                transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
            }

            a:hover {
                border: none;
            }

            a:hover:after {
                width: 100%;
                background-color: $dark-gray;
            }

            &.is-active {
                a:after {
                    width: 100%;
                }
                a:hover:after {
                    background-color: get-color(primary);
                }
            }
        }
    }
    .tabs-content{
        border: none;
    }
}
