//.text-left {
//    text-align: left;
//}
//.text-right {
//    text-align: right;
//}
//.text-center {
//    text-align: center;
//}
//.text-justify {
//    text-align: justify;
//}
.text-blue {
    color:#102E48;
    margin-bottom:0;
}
main {
    .ce-headline-center {
        text-align: center;
    }

    .ce-headline-right {
        text-align: right;
    }

    .ce-headline-left {
        text-align: left;
    }
    h1, .h1,{
     font-weight: 500;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        font-weight: 600;
        color: #B9CE37;
        @include breakpoint(xlarge) {
            margin-bottom: 2rem
        }
    }

    h1.large,
    h1.first_header {
        text-transform: uppercase;
        font-size: rem-calc(30px);
        @include breakpoint(medium) {
            font-size: rem-calc(80px);
        }
    }

    h1 {
        &.separator {
            @include clearfix;

            &::after {
                display: table;
                width: 110px;
                border-bottom: 2px solid get-color(primary);
                margin: 1rem auto 2rem 0;
            }

            &.text-center {
                &::after {
                    margin: 1rem auto 2rem;
                }
            }
        }
    }

    a {
        color: $secondary-color;
    }

    p {
        color: $body-font-color;
        font-size: 16px;
        line-height: 1.2;
        // line-height:20px;
        @include breakpoint(medium) {
            font-size: 20px;
        }
        @include breakpoint(large) {
            font-size: 24px;
        }
        @include breakpoint(xlarge) {
            font-size: 28px;
        }
    }

    p.subheader {
        font-size: 15px;
        // line-height:16px;
        margin-bottom: 1.3rem;
        @include breakpoint(medium) {
            font-size: 16px;
        }
        @include breakpoint(large) {
            font-size: 18px;
        }
        @include breakpoint(xlarge) {
            font-size: 20px;
        }
    }

    p.alert,
    p.info,
    p.note,
    p.success,
    p.warning {
        padding: 12px;
    }

    p.info {
        background-color: scale-color(get-color(primary),$lightness:80%);
    }

    p.note {
        color: get-color(primary);
        border: 1px solid get-color(primary);
    }

    p.success {
        background-color: scale-color(get-color(success),$lightness:80%);
    }

    p.warning {
        background-color: scale-color(get-color(warning),$lightness:80%);
    }

    p.alert {
        background-color: scale-color(get-color(alert),$lightness:80%);
    }

    blockquote {
        border-left: 2px solid;
        border-left-color: $primary-color;
        padding: 15px 40px;
        margin: 45px 0;

        p {
            font-size: 21px;
            line-height: 30px;
            font-weight: $global-font-weight-thin;

            span.autor {
                display: block;
                font-size: 14px;
                font-weight: 400;
                color: $medium-gray;

                &:before {
                    content: '\2014 \00a0';
                }
            }
        }
    }

    ul ul {
        list-style-type: circle;
    }

    ul ul ul {
        list-style-type: none;

        > li:before {
            content: "–";
            position: absolute;
            margin-left: -1.1em;
        }
    }

    ol ol {
        list-style-type: lower-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    ul.primary-bullets {
        list-style-type: none;
        margin-left: 0;

        li {
            padding: 0 0 0 1.25rem;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: get-color(primary);
                position: absolute;
                left: 0;
                top: 9px;
            }

            ul {
                margin-left: 0;

                li {
                    list-style-type: none;
                    padding: 0 0 0 1.25rem;

                    &:before {
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: none;
                        border: 1px solid get-color(primary);
                        position: absolute;
                        left: 0;
                        top: 9px;
                    }
                }
            }
        }
    }

    ol.primary-numbers {
        counter-reset: section;
        list-style-type: none;
        margin-left: 0;

        > li {
            position: relative;
            padding: 0 0 0 1.5rem;

            &:before {
                counter-increment: section;
                content: counter(section);
                background: get-color(primary);
                font-size: 11px;
                font-weight: bold;
                color: #fff;
                display: block;
                position: absolute;
                left: 0;
                top: 4px;
                text-align: center;
                height: 18px;
                line-height: 18px;
                vertical-align: middle;
                min-width: 18px;
                box-sizing: border-box;
                padding: 0 2px;
                -webkit-border-radius: 9px;
                -moz-border-radius: 9px;
                border-radius: 9px;
            }

            ol {
                list-style-type: lower-alpha;
            }
        }
    }
}
