.control {
  position: relative;
  display: inline-block;
  padding: 4px 15px 0 44px;
  cursor: pointer;
  box-sizing: border-box;
  min-height: 30px;
  font-size: 23px;
  line-height: 26px;
  color:#9A9999;
  &.control--radio {
    padding: 4px 45px 0 44px;
  }

}

.control input {
  position: absolute;
  //z-index: -1;
  z-index: #{map-get($zIndex, 'control__input')};
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 31px;
  height: 31px;
  border: 2px solid #9A9999;
  box-sizing: border-box;
  border-radius: 50%;
  background: $white;
  @include transition_ease(all);
}

/* Hover and focus states */
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  box-shadow: 0 1px 2px rgba(90, 90, 90, 0.1) inset;
  border:2px solid #707070;
}

.control input:disabled ~ .control__indicator {
  pointer-events: none;
  opacity: .6;
  background: #e6e6e6;
}

.control__indicator:after {
  position: absolute;
  display: none;
  content: '';
}

.control input:checked ~ .control__indicator:after {
  display: block;
}

.control--checkbox .control__indicator:after {
  //top: 6px;
  //left: 11px;
  //width: 6px;
  //height: 15px;
  //transform: rotate(45deg);
  //border: solid rgba(16, 46, 72, 1);
  //border-width: 0 3px 3px 0;
  top: 4px;
  left: 4px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #b9ce37;
  box-shadow: 0 0 1px 2px #b9ce37;
}

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}

.control--radio .control__indicator:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: rgb(16, 46, 72);
}

.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
