pre {
    padding:1em;
   border:1px solid #b6c8ce;
   background-color: $white;
    margin-bottom:1rem;
}
code {
border: none;
background-color: transparent;
}
