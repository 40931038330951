*,
*::after,
*::before {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

body,
html {
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

// html {
//     overflow: hidden;
// }


body {
    background-color: $white;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-family: $body-font-family;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: $global-lineheight;
    font-size: $default-font-size;
    flex-wrap: nowrap;
    @include flex-grid-layout(12);

    a {
        transition: $duration-medium all ease-out;
    }

    > * {
        flex: 0 0 auto; //ie fix
    }

}

main {
    max-width: 1920px;
    margin:0 auto;
    min-height: calc(100vh - 178px); // 100vh - (header + footer)
    /*> * {
        width: 100%;
        flex: 0 0 auto; //ie fix
    }*/
}

.extbase-debugger-floating {
    //z-index: 100!important;
    z-index: #{map-get($zIndex, 'extbase-debugger-floating')} !important;
}

img.expanding {
    width: 100%;
    max-width: none;
}

div.hr {
    clear: both;
    height: 0;
    //margin: $hr-margin;
    border-top: 0;
    border-right: 0;
    border-bottom: $hr-border;
    border-left: 0;
}
hr {
    margin: 15px 0;
    @include breakpoint(medium) {
        margin: 30px 0;
    }
}

div:not[class^="section"] {
    .pt-extra-small {
        @include top-padding(10px, 16px, 26px);
    }

    .pt-small {
        @include top-padding(15px, 24px, 39px);
    }

    .pt-medium {
        @include top-padding(25px, 40px, 65px);
    }

    .pt-large {
        @include top-padding(40px, 64px, 104px);
    }

    .pt-extra-large {
        @include top-padding(65px, 104px, 169px);
    }

    .pt-extra-small {
        @include bottom-padding(10px, 16px, 26px);
    }

    .pb-small {
        @include bottom-padding(15px, 24px, 39px);
    }

    .pb-medium {
        @include bottom-padding(25px, 40px, 65px);
    }

    .pb-large {
        @include bottom-padding(40px, 64px, 104px);
    }

    .pb-extra-large {
        @include bottom-padding(65px, 104px, 169px);
    }

    .mt-extra-small {
        @include top-margin(10px, 16px, 26px);
    }

    .mt-small {
        @include top-margin(15px, 24px, 39px);
    }

    .mt-medium {
        @include top-margin(25px, 40px, 65px);
    }

    .mt-large {
        @include top-margin(40px, 64px, 104px);
    }

    .mt-extra-large {
        @include top-margin(65px, 104px, 169px);
    }

    .mb-extra-small {
        @include bottom-margin(10px, 16px, 26px);
    }

    .mb-small {
        @include bottom-margin(15px, 24px, 39px);
    }

    .mb-medium {
        @include bottom-margin(25px, 40px, 65px);
    }

    .mb-large {
        @include bottom-margin(40px, 64px, 104px);
    }

    .mb-extra-large {
        @include bottom-margin(65px, 104px, 169px);
    }
}
