/* select */
.jcf-select {
  display: block;
  position: relative;
  background: $white;
  min-width: 50px;
  border-radius:6px;
  font-size: 15px;
  outline: 1px solid $medium-gray;

  &.jcf-drop-active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .jcf-select-text {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    display: block;
    font-size: 1em;
    line-height: 1.2em;
    padding:0.72em 0;
    font-weight: 700;
    color: $body-font-color;
    margin: 0 40px 0 11px;
  }

  .jcf-select-opener {
    cursor: pointer;
    position: absolute;
    width: 29px;
    bottom: 0;
    right: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 2px;
      top: 50%;
      margin-top: -8px;
      border-right: 2px solid $dark-gray;
      border-bottom: 2px solid $dark-gray;
      transform: rotate(45deg);
    }
  }

  .jcf-select-drop {
    position: absolute;
    margin-top: 0px;
    //z-index: 70;
    z-index: #{map-get($zIndex, 'jcf-select-drop')};
    top: 100%;
    left: 0;
    box-sizing: border-box;
    min-width: 100%;
    width: auto !important;

    .jcf-select-drop-content {
     outline: 1px solid $medium-gray;
    }

  }

  select {
    //z-index: 1;
    z-index: #{map-get($zIndex, 'jcf-select__select')};
    left: 0;
    top: 0;
  }

  &.jcf-disabled {
    opacity: 0.5;
  }

}

/* select options styles */
.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: $white;
  font-size: 0.93em;
  min-width: 100%;
  width: auto !important;

  .jcf-list-content {
    vertical-align: top;
    display: inline-block;
    overflow: auto;
    min-width: 100%;
    width: auto !important;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      box-sizing: border-box;
      border-bottom: 1px solid $white;

      .jcf-option {
        cursor: pointer;
        display: block;
        padding: 0.55em 30px 0.55em 10px;
        color: $dark-gray;
        min-height: 1em;
        line-height:1.2em;
        height: 1%;

        &.jcf-hover, &.jcf-selected {
          font-weight: 700;
          color: $body-font-color;
        }
      }

      .jcf-disabled {
        background: $white !important;
        color: $light-gray !important;
      }

      .jcf-optgroup-caption {
        white-space: nowrap;
        font-weight: bold;
        display: block;
        padding: 0 10px;
        cursor: default;
        color: $body-font-color;
      }

      .jcf-optgroup .jcf-option {
        padding-left: 30px;
      }
    }

  }

  .jcf-overflow {
    overflow: auto;
  }

}

/* scrollbars */
.jcf-scrollable-wrapper {
  box-sizing: content-box;
  position: relative;
  min-width: 100%;
  width: auto !important;

  .jcf-scrollbar-vertical {
    position: absolute;
    cursor: default;
    width: 12px;
    bottom: 0;
    right: 0;
    top: 0;

    .jcf-scrollbar-dec, .jcf-scrollbar-inc {
      height: 5px;
      width: 12px;
      left: 0;
      top: 0;
    }

    .jcf-scrollbar-inc {
      top: auto;
      bottom: 0;
    }

    .jcf-scrollbar-slider {
      background: #c2c2c3;
      width: 7px;

      .jcf-scrollbar-handle {
        background: $dark-gray;
        height: 1px;
        width: 7px;
      }
    }

  }

  .jcf-scrollbar.jcf-inactive {
    .jcf-scrollbar-handle {
      visibility: hidden;
    }

    .jcf-scrollbar-dec, .jcf-scrollbar-inc {
      background: #e3e3e3;
    }
  }
}
