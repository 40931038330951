header {
    ul.dropdown.menu {
        display: none;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height:100%;
        align-items: stretch;
        > li {
            flex-shrink: 0;
            flex-grow: 0;
            display: flex;
            align-items: center;
            &.is-dropdown-submenu-parent > a {
                padding-right: 0.7rem;
                &::after {
                    content:'';
                    display: none;
            //        border: none;
            //        // border-color: $white transparent transparent ;
            //        font-family: $icon-font-family;
            //        @extend .icon-chevron-down__c;
            //        margin-top: -10px;
            //        right: 30px;
                }
            }

            > a {
                &:hover {
                    text-shadow:0 0 1px rgb(16, 46, 72);
                }
            }


            .is-dropdown-submenu {
                background: rgba(16, 46, 72, 0.9);
                border:0;
            }
            &.opens-right { // sass-lint:disable-line no-qualifying-elements
                > .is-dropdown-submenu {
                    min-width: 430px;
                    padding-bottom: 70px;
                    border: 0;
                    background: transparent;
                    &:before {
                        height:calc(100% - 70px);
                        left:0;
                        right:0;
                        top:0;
                        position:absolute;
                        content:'';
                        background: rgba(16, 46, 72, 0.9);
                    }
                    &:after {
                        height:70px;
                        left:0;
                        right:0;
                        bottom:0;
                        position:absolute;
                        content:'';
                        background: rgba(16, 46, 72, 0.9);
                        clip-path: polygon(0 0, 100% 0, 100% 31%, 30% 100%, 0 58%);
                    }
                    li {
                        position: relative;
                    }
                }
            }

            .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a {
                &::after {
                    border: none;
                    right: 22px;
                    transform: translate(0, -50%);
                    width: auto;
                    height: auto;
                    margin-top: 0;
                }
                @extend .icon-chevron-straight-right--after;
            }

            .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a{
                &::after {
                    border: none;
                    left: 5px;
                    transform: translate(0, -50%);
                    width: auto;
                    height: auto;
                    margin-top: 0;
                }
                @extend .icon-chevron-straight-left--after;
            }

            a {
                // text-transform: uppercase;
                font-weight: $global-weight-medium;
                font-size: 20px;
                color: #102E48;
                padding: 0.5rem 0.7rem;
                line-height: 22px;
            }

            &.is-active > a {
                background: transparent;
                color: #102E48;
            }

            //&.active {
            //    > a {
            //        color: #102E48;
            //        background: transparent;
            //        border-bottom: 2px solid #102E48;
            //    }
            //}

            ul {
                li {
                    a {
                        // text-transform: none;
                        color: #fff;
                        padding: 1rem 2rem;
                        font-size: rem-calc(30px);
                        line-height: rem-calc(33px);
                        transition: all .2s ease;
                        background: transparent!important;
                        &:hover {
                            color: #B9CE37;
                            //font-weight: bold;
                            text-shadow: -1px 0 1px #B9CE37;
                        }
                    }

                    &.active > a {
                        //font-weight: $global-weight-bold;
                        text-shadow: -1px 0 1px #B9CE37;
                        background: transparent;
                    }

                    //&.is-active > a {
                    //    background: transparent;
                    //    color: $black;
                    //}
                    &:first-child {
                        a {
                            margin-top: 1rem;
                        }
                    }
                    //&:last-child {
                    //    a {
                    //        margin-bottom: 1rem;
                    //    }
                    //}
                }
            }
        }
        @include breakpoint(xlarge) {
            display: flex;
        }
    }
}

// menu off canvas
.twe-menu-mobile {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;
    transition: transform 0.5s ease, opacity .5s ease;
    opacity: 0;
    &.is-open {
        opacity: 1;
    }
    .twe-menu-top {
        padding-bottom: 129px;
        position: relative;
        .twe-menu-top-wrap {
            background: rgba(16, 46, 72, 0.9);
            max-width: $global-width;
            margin-left: auto;
            margin-right: auto;
            padding-left: $section-gutter-small - 5;
            padding-right: $section-gutter-small - 5;
            padding-top:rem-calc(45px);
            max-height: 55vh;
            overflow-y: auto;
            @include breakpoint(medium) {
                padding-left: $section-gutter-medium;
                padding-right: $section-gutter-medium;
            }
            @include breakpoint(large) {
                padding-left: $section-gutter-large;
                padding-right: $section-gutter-large;
            }
        }
        &:after {
            content:'';
            display:block;
            height: 109px;
            position: absolute;
            bottom:20.2px;
            left:0;
            right:0;
            background: rgba(16, 46, 72, 0.9);
            clip-path: polygon(0 0, 100% 0, 100% 31%, 30% 100%, 0 58%);
        }
        @include breakpoint(medium) {
            &:after {
                clip-path: polygon(0 0, 100% 0, 100% 31%, 78% 100%, 0 58%);
            }
        }
    }

    .twe-menu-bottom {
        height:145px;
        //background: url('../img/twe/menu_bg_2.png') no-repeat bottom right;
        position:relative;
        .twe-menu-bottom-wrap {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            > div {
                background: #B9CE37;
                &:nth-child(1) {
                    flex: 1 0 auto;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 20px 40px 20px 30px;
                    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
                }
                &:nth-child(2) {
                    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
                    margin-left: 2px;
                    padding: 30px 40px 20px 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @include breakpoint(medium) {
                    min-height: 140px;
                }
            }
            a {
                display:flex;
                flex-direction: column;
                color:#fff;
                justify-content: flex-end;
                align-items: center;
                -webkit-tap-highlight-color: transparent;
                > img {
                    display:block;
                    vertical-align: middle;
                    transition: transform .3s ease;
                }
                &:hover {
                    > img {
                        transform: scale(.9);
                    }
                }
                &:focus, &:active {
                    > img {
                        transform: scale(.8);
                    }
                }
            }
        }
    }
    .menu {
        li {
            a {
                outline: 0;
                color:#fff;
                font-weight: bold;
                font-size:rem-calc(30px);
                line-height: rem-calc(33px);
                background:transparent;
                transition: opacity .2s ease; // drill menu effect (for transparent background)
                @include breakpoint(medium) {
                    font-size:rem-calc(50px);
                    line-height: rem-calc(56px);
                    padding: 0.4rem 3rem;
                }
                &.is-active {
                    // color: $white;
                }
            }
            &.is-drilldown-submenu-parent {
                a {
                    position: relative;
                    @extend .icon-chevron-straight-right;
                    &:before {
                        display:inline-block;
                        position:absolute;
                        right:0;
                        top:50%;
                        transform: translate(0, -50%);
                        color:#B9CE37;
                        font-size:rem-calc(30px);
                        @include breakpoint(medium) {
                            font-size: rem-calc(46px);
                        }
                    }
                    &:after {
                        content:'';
                        display:none;
                    }
                }
                .js-drilldown-back {
                    a {
                        &:before {
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            color: #B9CE37;
                            font-size: rem-calc(30px);
                            height:auto;
                            width:auto;
                            border:none;
                            right:auto;
                            @include breakpoint(medium) {
                                font-size: rem-calc(46px);
                            }
                        }
                        @extend .icon-chevron-straight-left;
                    }
                }
            }
            .is-drilldown-submenu {
                background: transparent;
            }
        }
        // fix css for visiblity submenu
        &.invisible.visible {
            visibility: hidden;
        }
        &.invisible {
            > li > a{
                opacity: 0;
            }
        }
        &.visible {
            > li > a {
                opacity: 1;
            }
        }
    }
}
