.count-to {
    text-align: center;

    img {
        margin: 0 auto;
        max-width: 120px;
        height: auto;
        width: 100%;
    }

    p.timer {
        font-size: 40px;
        color: get-color(primary);
        padding: 0;
        margin: 0;
    }
}
