$icon-font-family: "icomoon" !default;

$q3i-button: (
    tiny: (
        font-size: rem-calc(14px),
        line-height: rem-calc(16px),
        padding: rem-calc(7px) rem-calc(23px)
    ),
    small: (
        font-size: rem-calc(20px),
        line-height: rem-calc(22px),
        padding: rem-calc(10px) rem-calc(23px)
    ),
    default: (
        font-size: rem-calc(28px),
        line-height: rem-calc(31px),
        padding: rem-calc(17px) rem-calc(48px)
    ),
    large: (
        font-size: rem-calc(34px),
        line-height: rem-calc(38px),
        padding: rem-calc(20px) rem-calc(54px)
    ),
)!default;

a.back_link,
a.external_link,
a.internal_link {
    &:before {
        font-size: 14px;
        font-family: $icon-font-family;
        margin-right: 2px;
    }

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}

//a.internal_link {
//   @extend .icon-arrow-right;
//}
//
//a.external_link {
//    @extend .icon-arrow-up-right;
//}
//
//a.back_link {
//    @extend .icon-arrow-left;
//}

a.more_link {
    &:after {
        content: "...";
    }

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}
//buttons

.btn {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 0 0 $global-margin 0;
    font-family: $body-font-family;
    -webkit-appearance: none;
    border: 1px solid transparent;
    transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50px;
    color: #102E48;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.47);
    font-size: map-get(map-get($q3i-button, tiny), font-size);
    line-height: map-get(map-get($q3i-button, tiny), line-height);
    padding: map-get(map-get($q3i-button, tiny), padding);
    @include breakpoint(medium) {
        font-size: map-get(map-get($q3i-button, small), font-size);
        line-height: map-get(map-get($q3i-button, small), line-height);
        padding: map-get(map-get($q3i-button, small), padding);
    }
    @include breakpoint(large) {
        font-size: map-get(map-get($q3i-button, default), font-size);
        line-height: map-get(map-get($q3i-button, default), line-height);
        padding: map-get(map-get($q3i-button, default), padding);
    }
    &[disabled] {
        background-color: #f6f6f6!important;
        color: #929da9!important;
    }
    &:focus,
    &:hover {
        background-color: #102e48;
        color: #ffffff;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.55);
    }

    &__tiny {
        font-size: map-get(map-get($q3i-button, tiny), font-size);
        line-height: map-get(map-get($q3i-button, tiny), line-height);
        padding: map-get(map-get($q3i-button, tiny), padding);
    }

    &__small {
        font-size: map-get(map-get($q3i-button, small), font-size);
        line-height: map-get(map-get($q3i-button, small), line-height);
        padding: map-get(map-get($q3i-button, small), padding);
    }

    &__large {
        font-size: map-get(map-get($q3i-button, large), font-size);
        line-height: map-get(map-get($q3i-button, large), line-height);
        padding: map-get(map-get($q3i-button, large), padding);
    }

    &__bg-secondary {
        background-color: get-color(secondary);

        &:focus,
        &:hover {
            background-color: scale-color(get-color(secondary), $lightness: -20%);
        }
    }

    &__bg-none {
        background-color: transparent;
        color: $primary-color;

        &:focus,
        &:hover {
            background-color: transparent;
        }
    }

    &__expand {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    &__hollow {
        border: 2px solid $primary-color;
        background-color: transparent;
        color:$primary-color;

        &:focus,
        &:hover {
            color: $white;
            background-color: $primary-color;
        }
    }

    &__with-icon-before {
        position: relative;
        padding-left: 2.5em;

        &:before {
            font-size: 18px;
            position: absolute;
            top: 0.55em;
            left: 0.5em;
            font-family: $icon-font-family;
        }
    }

    &__with-icon-after {
        position: relative;
        padding-right: 2.5em;

        &:after {
            font-size: 18px;
            position: absolute;
            top: 0.55em;
            right: 0.5em;
            font-family: $icon-font-family;
        }
    }

    &__margins {
        margin-top: 6rem!important;
    }

    &__news-back {
        background: #B9CE37;
        color: #fff;
        //@include breakpoint(large) {
        //    padding: map-get(map-get($q3i-button, small), padding);
        //}
    }
}
// @each $icon, $content in $icons {
//     .bt-#{$icon},
//     .bt-#{$icon}--small,
//     .bt-#{$icon}--large,
//     .bt-#{$icon}--hollow
//     {
//         &--after {
//             @extend .btn__with-icon-after;
//             &:after {
//                 content: #{$content}
//             };
//         }
//         &:not(&--after) {
//             &:before {
//                 content: #{$content}
//             };
//         }
//         @extend .btn__with-icon-before;
//     }
// }

// a.bt-primary-small {
//     @extend .btn;
//     @extend .btn__small;
// }

button.bt-primary,
a.bt-primary {
    @extend .btn;
}

button.bt-success,
a.bt-success {
    @extend .btn;
    background: #B9CE37;
    color:#fff;
    &:hover {
        background: #fff;
        color:#102E48;
    }
}

button.bt-primary-margin,
a.bt-primary-margin {
    @extend .btn;
    @extend .btn__margins;
}

button.bt-download,
a.bt-download {
    @extend .btn;
    padding-left: 52px;
    position: relative;
    &:before {
        position: absolute;
        left:10px;
        top:50%;
        transform:translate(0,-50%);
        font-size: rem-calc(32px);
        color:#B9CE37;
    }
    @extend .icon-download_filled;
    @include breakpoint(medium) {
        padding-left: 64px;
        &:before {
            font-size: rem-calc(42px);
        }
    }
}

button.bt-mail,
a.bt-mail {
    @extend .btn;
    padding-left: 52px;
    position: relative;
    &:before {
        position: absolute;
        left:10px;
        top:50%;
        transform:translate(0,-50%);
        font-size: rem-calc(32px);
        color:#B9CE37;
    }
    @extend .icon-email_filled;
    @include breakpoint(medium) {
        padding-left: 64px;
        &:before {
            font-size: rem-calc(42px);
        }
    }
}

button.bt-secondary,
a.bt-secondary {
    @extend .btn;
    @extend .btn__bg-secondary;
}

button.bt-primary-large,
a.bt-primary-large {
    @extend .btn;
    @extend .btn__large;
}

button.bt-primary-expanded,
a.bt-primary-expanded {
    @extend .btn;
    @extend .btn__expand;
}

button.bt-primary-hollow,
a.bt-primary-hollow {
    @extend .btn;
    @extend .btn__hollow;
}

button.bt-chevron-right,
a.bt-chevron-right {
    @extend .btn;
    @extend .btn__with-icon-before;
    //@extend .icon-chevron-right;
}

button.bt-chevron-left,
a.bt-chevron-left{
    @extend .btn;
    @extend .btn__with-icon-before;
    //@extend .icon-chevron-left;
}







button.news_btn-more,
a.news_btn-more {
    @extend .bt-chevron-right;

}

button.news_btn-more-small,
a.news_btn-more-small {
    @extend .btn__bg-none;
    @extend .btn__with-icon-after;
    //@extend .icon-chevron-right;
}

button.tx-kesearch_large{
    @extend .btn;
    @extend .btn__with-icon-before;
    @extend .icon-search;
}


//.section.dark {
.download-card,
.section.twe-section-04,
.section.twe-section-15 {
    .btn {
        &:focus,
        &:hover {
            background-color: #B9CE37;
            color: #ffffff;
        }
    }
}