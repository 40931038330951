.form-navigation {
  margin-top:2rem;
}

.form-group {
    margin-bottom:1rem;
}

label{
    font-size: inherit;
}

textarea.xlarge {
height: 5rem;
}

// Backend Validation

.form-control {
    &.error {
     border: 1px solid $alert-color;
   }

   &.error + .jcf-select {
     border: 1px solid $alert-color;
     .jcf-select-drop-content{
        outline: 1px solid $alert-color;
     }
   }
}
.control input{
    &.error + .control__indicator {
      border: 1px solid $alert-color;
    }
}

.parsley-errors-list {
  display: none;
}

.parsley-invalid:before {
  display: inline-block;
  //@include use-gutter('padding-bottom',1);
  color: #993333;
  content: attr(data-validation-error-message); // error message from this attr will appear
}

/* fix styles for inputs foundation */
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  // $input-padding: set only 1 param
  // padding 2 params
  padding:1.2rem 1.5rem;
  margin:0;
}
