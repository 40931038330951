@charset "UTF-8"; // Default Variables
// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
// $slick-font-path: "../fonts/" !default;
$slick-font-family: "icomoon" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $black !default;
$slick-dot-color: $black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "" !default;
$slick-next-character: "" !default;
$slick-dot-character: "\e920" !default;
$slick-dot-size: 36px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;
@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    } @else {
        @return url($slick-loader-path + $url);
    }
}
@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    } @else {
        @return url($slick-font-path + $url);
    }
}
/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("../img/ajax-loader.gif") center center no-repeat;
    }
}
/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}
/* Arrows */
//.slider-one {
//    margin-left: -($section-gutter-small);
//    margin-right: -($section-gutter-small);
//}
.twe-slick,
.fce-slick {

    .slick-next,
    .slick-prev {
        position: absolute;
        display: block;
        // height: 20px;
        // width: 20px;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        //background: transparent;
        //color: get-color(primary);
        top: 50%;
        bottom:auto;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        //border: none;
        outline: none;
        //z-index: 1;
        z-index: #{map-get($zIndex, 'slick-prev')};
        // @include breakpoint(small only) {
        //     display: none !important;
        // }

        // 32 / 27 / 50
        height: 32px;
        width: 32px;
        background: #fff;
        //color: #C3C3C3;
        color:#b9b9b9;
        border: 1px solid #C3C3C3;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(0,0,0,.16);
        &:focus,
        &:hover {
            border: 1px solid #b3b3b3;
            outline: none;
            box-shadow: 0 2px 6px rgba(0,0,0,.35);

            &:before {
                opacity: $slick-opacity-on-hover;
            }
        }

        &.slick-disabled:before {
            opacity: $slick-opacity-not-active;
        }

        &:before {
            font-family: $slick-font-family;
            font-size: 36px;
            line-height: 1;
            color: $slick-arrow-color;
            opacity: $slick-opacity-default;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .slick-prev {
        left: 10px;

        [dir="rtl"] & {
            left: auto;
            right: 0;
        }

        &:before {
            @extend .icon-chevron-round-left;
            [dir="rtl"] & {
                @extend .icon-chevron-round-right;
            }
        }
    }

    .slick-next {
        right: 10px;

        [dir="rtl"] & {
            left: 0;
            right: auto;
        }

        &:before {
            @extend .icon-chevron-round-right;
            [dir="rtl"] & {
                @extend .icon-chevron-round-left;
            }
        }
    }


    &.slick-arrows-bottom {
        @include breakpoint(small only) {
            .section.section_default {
                .section__inner {
                    padding-bottom:0!important;
                }
            }
        }
        .slick-next,
        .slick-prev {
            top: auto;
            bottom:21px;
            @include breakpoint(medium) {
                top: 50%;
                bottom: auto;
            }
        }
    }
}
/* Dots */

//.slick-dotted.slick-slider {
//    margin-bottom: 30px;
//}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: none!important;
    visibility: hidden;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    @include breakpoint(large) {
        display: block!important;
        visibility: visible;
    }

    li {
        position: relative;
        display: inline-block;
        height: 21px;
        width: 21px;
        margin: 0 2px;
        padding: 0;
        cursor: pointer;

        button {
            background: transparent;
            display: block;
            height: 21px;
            width: 21px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            cursor: pointer;
            border:2px solid transparent;
            transition: all .3s ease;
            border-radius:50%;
            position: relative;

            &:focus,
            &:hover {
                outline: none;

                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: '';
                background:#fff;
                width: 5px;
                height: 5px;
                border-radius:50%;
                line-height: 3px;
                text-align: center;
                opacity: $slick-opacity-not-active;
            }
        }

        &.slick-active {
            button {
                border:2px solid #fff;
                border-radius:50%;
                &:before {
                    opacity: $slick-opacity-default;
                }
            }
        }
    }
}
// Q3i SLICK OPTIONS

.slider--bright-arrows {
    .slick-next,
    .slick-prev {
        &:before {
            color:#b9b9b9;
            width: 30px;
            height: 30px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            background-size:10px;
            font-size: rem-calc(18px);
            line-height: rem-calc(30px);
            vertical-align: middle;
        }
    }
    .slick-prev {
        &:before {
            background-position: 45% 50%;
        }
    }
    .slick-next {
        &:before {
            background-position: 55% 50%;
        }
    }
}

.slider--bright-dots {
    .slick-dots {
        li {
            button:before {
                color: $white;
            }

            &.slick-active button:before {
                color: $white;
                opacity: $slick-opacity-default;
            }
        }
    }
}

.slider--dots-inside {
    .slick-dots {
        bottom: 75px;
    }
}
.twe-slick-02 {
    .slider--dots-inside {
        .slick-dots {
            bottom: 115px;
        }
    }
}

.slider--hide-arrows-on-small {
    .slick-next,
    .slick-prev {
        @include breakpoint(small only) {
            display: none !important;
        }
    }
}

.slider--expand-on-small {
    @include breakpoint(small only) {
        margin-left: rem-calc(-$space-xs);
        margin-right: rem-calc(-$space-xs);
    }
}

.slider--arrows-outside {
    .slick-next,
    .slick-prev {
        display: none!important;
    }

    @media screen and (min-width:1230px) {
        .slick-prev {
            display: inline-block!important;
            left: -40px;
        }

        .slick-next {
            display: inline-block!important;
            right: -40px;
        }
    }
}
//Q3i SLICK VERIANT EXAMPLES
.twe-slick,
.fce-slick {
    .textpic_images {
        margin-bottom:0 !important;
    }
}
.twe-slick,
.fce-slick {
    @extend .slider--bright-arrows;
    @extend .slider--bright-dots;
    @extend .slider--dots-inside;

}

.slider-two {
    @extend .slider--expand-on-small;
}

.slider-three {
    @extend .slider--hide-arrows-on-small;
    @extend .slider--arrows-outside;
}



@include breakpoint(medium) {
    //.slider-one {
    //    margin-left: -($section-gutter-medium);
    //    margin-right: -($section-gutter-medium);
    //}
    .twe-slick,
    .fce-slick{
        .slick-next,
        .slick-prev {
            height: 27px;
            width: 27px;
            top: 50%;
            bottom:auto;
        }
    }
    .slider--bright-arrows {
        .slick-next,
        .slick-prev {
            &:before {
                width: 25px;
                height: 25px;
                background-size: 8px;
                font-size: rem-calc(14px);
                line-height: rem-calc(25px);
            }
        }
        .slick-prev {
            &:before {
                background-position: 40% 50%;
            }
        }
        .slick-next {
            &:before {
                background-position: 60% 50%;
            }
        }
    }
}

@include breakpoint(large) {
    //.slider-one {
    //    margin-left: -($section-gutter-large);
    //    margin-right: -($section-gutter-large);
    //}
    .twe-slick,
    .fce-slick{
        .slick-next,
        .slick-prev {
            height: 50px;
            width: 50px;
        }
        .slick-prev {
            left: 1%;
        }
        .slick-next {
            right: 1%;
        }
    }
    .slider--bright-arrows {
        .slick-next,
        .slick-prev {
            &:before {
                width: 48px;
                height: 48px;
                background-position: center;
                background-repeat: no-repeat;
                background-size:unset; // default
                font-size: rem-calc(26px);
                line-height: rem-calc(50px);
                vertical-align: middle;
            }
        }
    }
}

@media print, screen and (min-width: 90em) {
    .twe-slick,
    .fce-slick{
        .slick-prev {
            left: 3%;
        }
        .slick-next {
            right: 3%;
        }
    }
}

@media print, screen and (min-width: 110em) {
    .twe-slick,
    .fce-slick{
        .slick-prev {
            left: 5%;
        }
        .slick-next {
            right: 5%;
        }
    }
}

.slider-logos {
    display:flex;
    justify-content: center;
    .slick-list {
        max-width: $global-width - ($section-gutter-large * 2);
        margin-left: $section-gutter-small;
        margin-right: $section-gutter-small;
        @include breakpoint(medium) {
            margin-left: $section-gutter-medium;
            margin-right: $section-gutter-medium;
        }
        @include breakpoint(large) {
            margin-left: $section-gutter-large;
            margin-right: $section-gutter-large;
        }
    }
}
