@charset 'utf-8';

@import 'foundation';

/* Project Contants and Mixins */
@import 'constants';
@import 'mixins';

@import 'motion-ui';


@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-grid;
//
//@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
// @include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-range-input;
// @include foundation-orbit;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
// @include foundation-title-bar;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;


/*
  Fonts
 */
@import "font_face";
/*
  Effects
 */
@import "effects";

/* Utilities */

@import "utilities/icomoon";
@import "utilities/buttons";
@import "utilities/pagination";
@import "utilities/aos";

/* Base styles */
@import 'base/global';
@import 'base/templates';
@import "base/hamburger";
@import "base/header";
@import "base/footer";
@import 'base/code';
@import "base/magellan";
@import "base/menu-header";
@import "base/menu-offcanvas";
@import "base/menu-sidebar";

/*Grid*/
@import "grid/grid";

/*Typical Page Content*/
@import 'typical-page-content/typography';
@import "typical-page-content/typography_dark";
@import "typical-page-content/textpic";
@import "typical-page-content/filelinks";
@import "typical-page-content/tables";
@import "typical-page-content/menus";
@import "typical-page-content/swipebox";

/*Q3i Components*/
@import "q3i-components/sections";
@import "q3i-components/video_bg";
@import "q3i-components/panel";
@import "q3i-components/cards";
@import "q3i-components/isotope";
@import "q3i-components/timeline";
@import "q3i-components/pagetitles";
@import "q3i-components/breadcrumbs";
@import "q3i-components/maps";
@import "q3i-components/tabs_minimal";
@import "q3i-components/tabs_accordion";
@import "q3i-components/count_to";
@import "q3i-components/slick";
@import "q3i-components/slick-theme";
@import "q3i-components/social_links";
@import "q3i-components/twe_sections";
@import "q3i-components/twe_ce";
@import "q3i-components/videojs";
@import "q3i-components/videoplayer";

/*Forms*/
@import "forms/forms";
@import "forms/select";
@import "forms/inputfile";
@import "forms/checkbox-radio";

/*TYPO3 Plugins*/
@import "typo3-plugins/cookieconsent";
@import "typo3-plugins/ke_search";
@import "typo3-plugins/news";
