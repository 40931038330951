$icomoon-font-path: "../fonts" !default;


$icons: (
  chevron: "\e900",
  chevron-round-left: "\e901",
  chevron-round-right: "\e902",
  chevron-straight-left: "\e903",
  chevron-straight-right: "\e904",
  download: "\e905",
  mail: "\e906",
  play: "\e907",
  search: "\e908",
  share: "\e909",
  social_facebook: "\e90a",
  social_linkedin: "\e90b",
  social_vimeo: "\e90c",
  social_youtube: "\e90d",
  triangle: "\e90e",
  twe-diamond: "\e90f",
  twe-fire: "\e910",
  twe-flask: "\e911",
  twe-hammer: "\e912",
  twe-leafs: "\e913",
  twe-recycle: "\e916",
  twe-stear: "\e917",
  twe-voc: "\e919",
  arrow_filled: "\e91a",
  download_filled: "\e91b",
  email_filled: "\e91c",
  phone_filled: "\e91d",
  plus: "\e914",
  minus: "\e915",
  close: "\e918",
  circle: "\e91f",
  dot: "\e920",
  info: "\e91e"
);

@font-face {
  font-family: 'icomoon';
  src:
    url('#{$icomoon-font-path}/icomoon.ttf?b7wv64') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?b7wv64') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?b7wv64#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-font /*, [class^="icon-"], [class*=" icon-"]*/ {
  /* if extending is not possible use mixin (but it should be possible, it's problematic mainly in breakpoints, but you could probably set those properties outside of breakpoint */
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-font {
  @include icon-font;
}

@each $name, $icon in $icons {
  .icon-#{$name}__c { // usefull for extend inside before/after
    content: $icon;
  }
  .icon-#{$name} {
    &:before {
      @extend .icon-font; //extandable now
      @extend .icon-#{$name}__c;
    };
  }
  .icon-#{$name}--after {
    &:after {
      @extend .icon-font;
      @extend .icon-#{$name}__c;
    };
  }
}
