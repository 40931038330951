.tabs_block {
  .tabs {
    background: none;
    border: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    li {
      width: 100%;
      margin: 0 1px 0 0;

      &:last-child {
        margin: 0 0 0 0;
      }

      a {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        padding: 5px 10px;
        line-height: 55px;
        min-height: 55px;
        font-size: 20px;
        color: $body-font-color;
        background: #e6e6e6;
        text-decoration: none;

      }

      &.is-active {
        a {
          color: #fff;
          background: $medium-gray;
        }
      }

    }

  }

  .tabs-content {
    background: none;
    border: none;
    color: $body-font-color;

    .tabs-panel {
      @include breakpoint(large) {
        padding: 35px 30px;
      }

    }

  }

  .accordion {
    background: none;

    li {
      width: 100%;
      margin: 0 0 1px 0;

      &:last-child {
        margin: 0 0 0 0;
      }

      a {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        padding: 5px 20px;
        line-height: 55px;
        min-height: 55px;
        font-size: 20px;
        color: $body-font-color;
        background: #e6e6e6;
        text-decoration: none;
        border: none;

      }

      &.is-active {
        a {
          color: #fff;
          background: $medium-gray;
        }
      }

    }

  }

  .accordion-item {
    .accordion-title {

      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 2px;
        background: $body-font-color;
        margin-top: 0;
        transform: translate(0px, -50%);
        right: 15px;
      }

      &:after {
        content: '';
        position: absolute;
        right: 19px;
        top: 50%;
        transform: translate(0px, -50%);
        background:$body-font-color;
        width: 2px;
        height: 10px;
      }

    }

    &.is-active {
      .accordion-title {

        &:before {
          display: none;
        }
        &:after {
          display: none;
        }

      }
    }
  }

  .accordion-content {
    border: none;
    padding: 25px 0;
    @include breakpoint(medium) {
      padding: 25px 20px;
    }
  }

}

ul.accordion {
    .accordion-item {
        .accordion-title{
            text-transform: uppercase;
            border-bottom: 1px solid $light-gray;
            font-weight: $global-weight-bold;
            letter-spacing: 0.05em;
            line-height: 1.4;
            padding: 1.25rem 2rem 1.25rem 1rem;
            &:before {
                font-size: 18px;
            }
        }
        .accordion-content{
            padding: 1rem;
            border-bottom: 1px solid $light-gray;
        }
    }
}
