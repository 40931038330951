/**
 * Header styles
 */
header {
  height: $header-height-small;
  background: $white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  //z-index: 90;
  z-index: #{map-get($zIndex, 'header')} !important;
  transition: box-shadow 0.3s;
  @include breakpoint(medium) {
    height: $header-height-medium;
  }
  @include breakpoint(large) {
    height: $header-height-large;
    transition: height 0.3s, box-shadow 0.3s, background 0.3s;
  }

  // Scroll position header styles

  &.smaller {
    box-shadow: 0 1px 1px 0 rgba(154, 153, 153, 0.75);
  //  @include breakpoint(large) {
  //    height: $header-height-medium;
  //  }
  //  a.logo-link img {
  //    @include breakpoint(large) {
  //      height: 43px;
  //    }
  //  }
  }

  .header-container {
    display:flex;
    justify-content: space-between;
    align-items: center;
    min-width: 320px;
    height: 100%;
    //position: relative;
    //@include clearfix;
    max-width: $global-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $section-gutter-small;
    padding-right: $section-gutter-small;
    @include breakpoint(medium) {
      padding-left: $section-gutter-medium;
      padding-right: $section-gutter-medium;
    }
    @include breakpoint(large) {
      padding-left: $section-gutter-large;
      padding-right: $section-gutter-large;
    }
  }

  a.logo-link {
    display:block;
    flex:1 0 auto;
    img {
      //position: absolute;
      //left: 80px;
      //top: 50%;
      //transform: translate(0px, -50%);
      height: 43px;
      //z-index: 30;
      z-index: #{map-get($zIndex, 'logo')} !important;
      display: block;
      width: auto;
      box-sizing: border-box;
      transition: height 0.3s;
      @include breakpoint(medium) {
        height: 65px;
      }
      @include breakpoint(large) {
        height: 65px;
      }
    }
  }
  a.logo-link.desktop {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
  a.logo-link.mobile {
    display: block;
    @include breakpoint(medium) {
      display: none;
    }
  }

  .main_menu {
    list-style: none;
  }
}

/**
 * Language selector styles
 */
.language-selector {
  position: relative;
  //z-index: 5;
  z-index: #{map-get($zIndex, 'language-selector')} !important;
  .language-button {
    box-sizing: border-box;
    padding: 0 0 0 0;
    height: 22px;
    line-height: 22px;
    text-transform: uppercase;
    font-size: 20px;
    color: #9A9999;
    cursor: pointer;
    transition: color 0.5s ease;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    .select-icon {
      display:block;
      width:10px;
      height:12px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAABHNCSVQICAgIfAhkiAAAANFJREFUGFdjnDVjxgYGRkZ/BgLg////bzn+/FFnnD17tsy/v3+vMzIy8uDV8+9fYlpm5gJGkKKZM2cWABn9eDQcTEtPdwDJgzWsWrWK+f3796eAHCN0TUCn/GRkYtJLS0u7BdcAtcUIqOEUkM2MrOk/A0Njenp6A0wMbAMMAANgAjAA8pGEbv34+VMvLy/vJ1YNU6dO5WFlYbkOlJQBK2BkdAA65SCyoSg2QJ0WABRcD3TKQqBTEtD9hKEBpGDWzJlz2X//LovPyXlLlAY8wcsAAJTcROdPFeGvAAAAAElFTkSuQmCC') center center no-repeat;
      margin: 0 0 0 5px;
    }

  }

  .dropdown-pane {
    background: $white;
    position: absolute;
    right: 0 !important;
    left: auto !important;
    top: 35px !important;
    width: auto !important;
    max-width: 500px !important;
    border: none;
    box-sizing: border-box;
    padding: 0;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.34);
    @include breakpoint(medium) {
      top: 35px !important;
      right: 0 !important;
    }
  }

  .language-list {
    margin: 0;
    list-style-type: none;

    li {
      padding: 0;

      a {
        white-space: nowrap;
        padding: 0 10px 0 7px;
        display: block;
        font-size: 14px;
        font-weight: $global-weight-bold;
        text-decoration: none;
        line-height: 30px;

        &:hover {
          background: $light-gray;
        }
      }

      &:last-child {
        a {
          padding-bottom: 2px;
        }
      }

      &.active {
        a {
          color: get-color(primary);
          background: $light-gray;
        }
      }
    }
  }
}

.header-spacer {
  min-height: $header-height-small;
  background: $white;
  transition: height 0.3s;
  @include breakpoint(medium) {
    min-height: $header-height-medium;
  }
  @include breakpoint(large) {
    min-height: $header-height-large;
  }
}

/**
 * Main menu
 */
ul.dropdown.menu {
  display: none;
  flex:1 1 auto;
  @include breakpoint(large) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    > li {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}

.twe-menu-mobile {
  .menu {
    li .menu {
      display: none;
      position: absolute;
    }
  }
}

.header-items {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  & > * {
    margin: 0 0 0 10px;
  }

  padding-right: 40px;
  flex: 0 1 auto;

  @include breakpoint(medium) {
    padding-right: 60px;
  }

  @include breakpoint(large) {
    padding-right: 20px;
  }

  @include breakpoint(xlarge) {
    //margin-top: 7px;
    padding-right: 0;
    flex:1 1 auto;
  }

}

/**
 * Search in header
 */
.search-selector {
  svg {
    vertical-align: middle;
  }
}

.search-header {
  vertical-align: middle;
  &__trigger {
    cursor: pointer;
    font-size: rem-calc(24px);
    margin: 0;
    padding: 0;
    border: none;
    background:transparent;
    .icon-close,
    .icon-search {
      vertical-align: middle;
      color: #9a9999;
      &:before {
        vertical-align: middle;
      }
    }
    &.hover {
      background:transparent;
      .icon-close,
      .icon-search {
        color: #102e48;
      }
    }
  }

  &__form {
    //@include breakpoint(small only) {
      position: absolute;
      width: 100%;
      left: 0 !important;
      right: 0 !important;
      top: 95px !important;
    //}
    z-index: 4;
    box-sizing: border-box;
    border: none !important;
    margin: 0;
    background: rgba(16, 46, 72, 0.9);
    padding: 27px 0;

    @include breakpoint(large) {
    //  position: relative;
    //  padding: 0;
      top: 111px !important;
    //  left: 0 !important;
    //  display: block !important;
    //  visibility: visible !important;
    //  width: 250px;
    }
    //@include breakpoint(large) {
    //  width: 300px;
    //}

    > form {
      max-width: $global-width;
      margin-left: auto;
      margin-right: auto;
      padding-left: $section-gutter-small;
      padding-right: $section-gutter-small;
      @include breakpoint(medium) {
        padding-left: $section-gutter-medium;
        padding-right: $section-gutter-medium;
      }
      @include breakpoint(large) {
        padding-left: $section-gutter-large;
        padding-right: $section-gutter-large;
      }
      display:flex;
    }

  }

  &__input {
    position: relative;
    background: #fff;
    border-radius:30px;
    overflow: hidden;
    flex: 1 0 auto;
    border: none;
    input[type="text"] {
      display: block;
      width: 100%;
      margin: 0 0 0 0;
      padding: 16px 16px;
      box-sizing: border-box;
      font-size: rem-calc(28px);
      height: 61px;
      box-shadow: unset;
      border: none;
      @include breakpoint(large) {
        padding: 16px 70px;
      }
      &:focus {
        //box-shadow: 0 0 1px 1px #B9CE37 inset;
        border: none;
      }
    }
  }

  &__submit {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 61px;
    height: 61px;
    font-size: rem-calc(28px);
    color:#fff;
    border: none;
    cursor: pointer;
    background: #B9CE37;
    border-radius:30px;
    > span {
      visibility: hidden;
      display: none;
      vertical-align: middle;
    }
    @include breakpoint(medium) {
      right: 0;
      top: 0;
      width: auto;
      padding:0 25px;
      > span {
        visibility: visible;
        display: inline;
      }
    }

    &:before {
      @extend .icon-search;
      font-family: $icon-font-family;
      text-indent: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-size: rem-calc(26px);
      display: inline-block;
      vertical-align: middle;
    }
    &:hover {
      background: #a9bb38;
    }
  }

}

.login-block {
  .btn__icon-login {
    margin: 0;
    @include breakpoint(small only) {
      padding: rem-calc(3px);
    }
    @include breakpoint(medium) {
      line-height: 1;
    }

    &:before {
      left: 0;
      top: 1px;
      @include breakpoint(medium) {
        display: none;
      }
    }

    span {
      display: none;
      @include breakpoint(medium) {
        display: inline-block;
      }
    }

  }

  &__form {
    @include breakpoint(small only) {
      position: absolute;
      left: auto !important;
      right: -20px !important;
      top: 40px !important;
      width: 100vw;
    }

    box-sizing: border-box;
    border: none !important;
    margin: 0;
    padding: 15px 20px;
    box-shadow: 0 5px 8.1px 1.9px rgba(0, 0, 0, 0.34);

    input, .btn {
      margin: 0 !important;
    }

    @include breakpoint(medium) {
      width: 320px;
      position: absolute;
      left: auto !important;
      right: 0 !important;
      top: 52px !important;
    }

  }
}
