.map_block {
    height: 250px;
    width: 100%;
    @include breakpoint(medium) {
      height: 350px;
    }
    @include breakpoint(large) {
      height: 450px;
    }

}
