.list-files {
  margin:15px 0;
  .list-filter {
    font-size:rem-calc(20px);
    line-height:rem-calc(22px);
    color:#000000;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    p {
      font-size:rem-calc(14px);
      line-height:rem-calc(16px);
      color:#000000;
      align-items: center;
      margin-bottom:0;
    }
    @include breakpoint(medium) {
      font-size:rem-calc(20px);
      line-height:rem-calc(22px);
      p {
        font-size:rem-calc(20px);
        line-height:rem-calc(22px);
      }
    }
    .btn-filter {
      cursor: pointer;
      transition: all .3s ease;
      background: #B9CE37;
      color:#fff;
      border-radius: 50px;
      padding: 0 12px;
      height: 33px;
      font-size:rem-calc(14px);
      line-height:rem-calc(16px);
      border:0;
      box-shadow:unset;
      z-index: 1;
      position: relative;
      text-align: center;
      min-width: 100px;
      &:hover {
        background: #a9bb38;
        color:#fff;
      }
      @include breakpoint(medium) {
        font-size:rem-calc(20px);
        line-height:rem-calc(22px);
        padding: 14px 24px;
        height: 50px;
      }
      @include breakpoint(large) {
        font-size:rem-calc(28px);
        line-height:rem-calc(31px);
        padding: 10px 30px;
      }
    }
    .dropdown-filter {
      margin-left:22px;
      position: relative;
    }
    .dropdown-items {
      padding-top: 16px;
      border-radius:0 0 20px 20px;
      display: none;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      overflow: hidden;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
      @include breakpoint(medium) {
        padding-top: 25px;
      }
    }
    .dropdown-items .item {
      text-align: center;
      padding: 4px;
      cursor: pointer;
      font-size:rem-calc(14px);
      line-height:rem-calc(16px);
      @include breakpoint(medium) {
        font-size:rem-calc(20px);
        line-height:rem-calc(22px);
        padding: 7px;
        &:first-child {
          margin-top:7px;
        }
        &:last-child {
          margin-bottom:7px;
        }
      }
    }
    .dropdown-items .item:hover {
      background-color: #f0f0f0;
    }
  }
  .list-items {
    .list-item {
      padding:32px 0;
      border-top:1px solid rgba(0, 0, 0, 0.2);
      display:flex;
      justify-content: space-between;
      align-items: center;
      color:#102E48;
      .list-item-title {
        > div:nth-child(1) {
          font-weight: bold;
          font-size: rem-calc(20px);
          line-height: rem-calc(22px);
        }
        > div:nth-child(2) {
          font-size: rem-calc(16px);
          line-height: rem-calc(17px);
        }
        @include breakpoint(medium) {
          > div:nth-child(1) {
            font-weight: bold;
            font-size: rem-calc(29px);
            line-height: rem-calc(32px);
          }
          > div:nth-child(2) {
            font-size: rem-calc(23px);
            line-height: rem-calc(26px);
          }
        }
      }
      .list-item-buttons {
        display:flex;
        gap:10px;
        a {
          cursor: pointer;
          width:47px;
          height:47px;
          background: #B9CE37;
          display:inline-block;
          border-radius:50%;
          font-size: rem-calc(24px);
          line-height: rem-calc(47px);
          padding: 0;
          color: #fff;
          box-shadow:unset;
          text-align: center;
          span {
            visibility: hidden;
            display: none;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          &:before {
            display: inline-block;
          }
          @include breakpoint(medium) {
            font-size: rem-calc(36px);
            line-height: rem-calc(70px);
            width:70px;
            height:70px;
          }
          &.download {
            //background-color: #B9CE37;
            //background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4zMiIgaGVpZ2h0PSIyNC44MTEiIHZpZXdCb3g9IjAgMCAyMS4zMiAyNC44MTEiPg0KICA8ZyBpZD0iR3JvdXBfNDc3OCIgZGF0YS1uYW1lPSJHcm91cCA0Nzc4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMuMDYxIC0xMC40ODQpIj4NCiAgICA8ZyBpZD0iR3JvdXBfMjUyNiIgZGF0YS1uYW1lPSJHcm91cCAyNTI2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNi4zODYgMTAuNDg0KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMDg5IiBkYXRhLW5hbWU9IlBhdGggMTA4OSIgZD0iTS05OTYzLjc4MiwxNjk4Ni4yNjJ2MTYuMjExIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5OTcxLjI4OSAtMTY5ODYuMjYyKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz4NCiAgICAgIDxwYXRoIGlkPSJQYXRoXzEwOTAiIGRhdGEtbmFtZT0iUGF0aCAxMDkwIiBkPSJNLTk5NTcuNjY1LDE2OTk1LjkyOGwtNy4xNzYsNy45NTUtNy40OTMtNy45NTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk5NzIuMzM0IC0xNjk4Ny40NDMpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPg0KICAgIDwvZz4NCiAgICA8cGF0aCBpZD0iUGF0aF8xMDkxIiBkYXRhLW5hbWU9IlBhdGggMTA5MSIgZD0iTS05OTczLjY1NSwxNzAwMS4yMzh2NS40MjZoMTkuMzJ2LTUuNDI2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5OTg3LjcxNiAtMTY5NzIuMzY5KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz4NCiAgPC9nPg0KPC9zdmc+DQo=);
            //background-repeat: no-repeat;
            //background-position: center;
            //@include breakpoint(medium) {
            //  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC44MzkiIGhlaWdodD0iMzYuNTM5IiB2aWV3Qm94PSIwIDAgMzAuODM5IDM2LjUzOSI+DQogIDxnIGlkPSJHcm91cF80Nzc3IiBkYXRhLW5hbWU9Ikdyb3VwIDQ3NzciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xOS45ODcgLTE1LjY1KSI+DQogICAgPGcgaWQ9Ikdyb3VwXzI1MjYiIGRhdGEtbmFtZT0iR3JvdXAgMjUyNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjQuNDU5IDE1LjY1KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMDg5IiBkYXRhLW5hbWU9IlBhdGggMTA4OSIgZD0iTS05OTYzLjc4MiwxNjk4Ni4yNjJ2MjQuMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTk3NC45ODcgLTE2OTg2LjI2MikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMDkwIiBkYXRhLW5hbWU9IlBhdGggMTA5MCIgZD0iTS05OTUwLjQzNywxNjk5NS45MjhsLTEwLjcxMSwxMS44NzMtMTEuMTg2LTExLjg3MyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTk3Mi4zMzQgLTE2OTgzLjI2NCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+DQogICAgPC9nPg0KICAgIDxwYXRoIGlkPSJQYXRoXzEwOTEiIGRhdGEtbmFtZT0iUGF0aCAxMDkxIiBkPSJNLTk5NzMuNjU1LDE3MDAxLjIzOHY4LjFoMjguODM5di04LjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk5OTQuNjQzIC0xNjk1OC4xNDYpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPg0KICA8L2c+DQo8L3N2Zz4NCg==);
            //}
            @extend .icon-download
          }
          &.preview {
            //background-color: #B9CE37;
            //background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi4xNDYiIGhlaWdodD0iMTkuODgyIiB2aWV3Qm94PSIwIDAgMTIuMTQ2IDE5Ljg4MiI+DQogIDxnIGlkPSJHcm91cF8yNTI3YSIgZGF0YS1uYW1lPSJHcm91cCAyNTI3YSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC42ODYgMTkuMTU0KSByb3RhdGUoLTkwKSI+DQogICAgPHBhdGggaWQ9IlBhdGhfMTA5MCIgZGF0YS1uYW1lPSJQYXRoIDEwOTAiIGQ9Ik0xOC40MTEsMCw5LjQsOS45ODUsMCwwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPg0KICA8L2c+DQo8L3N2Zz4NCg==);
            //background-repeat: no-repeat;
            //background-position: center;
            //@include breakpoint(medium) {
            //  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy4wNjYiIGhlaWdodD0iMjguOTUyIiB2aWV3Qm94PSIwIDAgMTcuMDY2IDI4Ljk1MiI+DQogIDxnIGlkPSJHcm91cF8yNTI3IiBkYXRhLW5hbWU9Ikdyb3VwIDI1MjciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNjg2IDI4LjIyNCkgcm90YXRlKC05MCkiPg0KICAgIDxwYXRoIGlkPSJQYXRoXzEwOTAiIGRhdGEtbmFtZT0iUGF0aCAxMDkwIiBkPSJNMjcuNDgxLDAsMTQuMDM3LDE0LjksMCwwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPg0KICA8L2c+DQo8L3N2Zz4NCg==);
            //}
            @extend .icon-chevron-straight-right
          }
          &:hover {
            background-color: #a9bb38;
          }
        }
      }
    }
  }
}


.vue-app {
  height: 60vh;
  overflow: hidden;
  clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
  background: #102E48;
  position: relative;
  &:after {
    content:'vue app section';
    display: inline-block;
    color: #fff;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
  }
}