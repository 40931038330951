

// containers
// -----------
$global-width: 1598px;
$grid-row-width-narrow: 1200px !default;
$grid-row-width-expanded: 1798px !default;

// Blocks
// -----------
$block-border:  1px solid $medium-gray ;
$block-radius: 0 ;
$block-padding: 1rem ;

// Dark Styles
$header-dark-color: $white;
$body-dark-color: $white;
$anchor-dark-color: scale-color($anchor-color, $lightness: +70%);
$anchor-dark-color-hover: scale-color($anchor-color, $lightness: +50%);

// Header heights
$header-height-small: rem-calc(95);
$header-height-medium: rem-calc(111);
$header-height-large: rem-calc(111);

//Section inner padding left and right
$section-gutter-small:25px;
$section-gutter-medium:85px;
$section-gutter-large:113px;


// Row Gutters
$grid-gutter-default-small: 10px;
$grid-gutter-default-medium: 14px;
$grid-gutter-default-large: 18px;

$grid-gutter-medium-small: 10px;
$grid-gutter-medium-medium: 18px;
$grid-gutter-medium-large: 24px;

$grid-gutter-large-small: 10px;
$grid-gutter-large-medium: 18px;
$grid-gutter-large-large: 36px;


// Spaces
$space-xs: 10px;
$space-s: 20px;
$space-m: 40px;
$space-l: 80px;
$space-xl: 100px;


// Font sizes
// -----------
$default-font-size: rem-calc(14px);
$global-font-weight-thin: 100;
$global-weight-medium: 400;

// icomoon
// -----------
$icon-font-family: "icomoon";


// delay
// -----------
$duration-short: 0.2s;
$duration-medium: 0.3s;
$duration-long: 0.6s;
