/**************************************/
/* Default styles for news extension */
/**************************************/
.news {
    .clear {
        clear: both;
    }

    .nav {
        ul {
            float: left;
            display: inline;
            margin: 0;
        }

        li {
            float: left;
        }

        a {
            float: left;
            display: block;
        }

        &:after {
            content: ".";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }
    }

    article {
        padding-bottom: 15px;
        margin-bottom: 15px;

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        &:after {
            content: ".";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }

        .news-img-wrap {
            // float: left;
            margin: 0 0 1rem;

            a {
                display: block;
                // float: left;
            }

            img {
                // float: left;
                height: auto;
                width: auto;
            }
        }
    }

    .meta {
        clear: both;
        padding: 10px 0 0;
        margin-top: 15px;
        color: $medium-gray;

        span {
            display: inline-block;
            padding-right: 8px;
            margin-right: 8px;
        }
    }

    .no-news-found {
        color: $warning-color;
        font-style: italic;
    }
}
/* category menu view - not implemented yet */
.news-category-view {
    ul li {
        padding: 5px 8px;

        li {
            padding: 2px 8px 2px 12px;
        }
    }

    > ul > li:nth-child(even) {
        background: #f9f9f9;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }

    .category-desc {
        padding-left: 10px;
        margin-left: 10px;
        border-left: 1px solid #ddd;
    }
}
/* single view */
.news-single {
    article {
        // media files
        .news-img-wrap {
            margin: 0 0 25px;

            .outer {
                clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
                overflow: hidden;
                max-height: 550px;

                img {
                    width: 100%;
                    min-height: 100%;
                    height: auto;
                    object-fit: cover;
                }

                .news-img-caption {
                    visibility: hidden;
                    display: none;
                }
            }
        }
        // meta
        .meta {
            font-size:rem-calc(15);
            line-height:rem-calc(16);
            color:#fff;
        }
        // header
        .header {
            margin-bottom:2rem;
            h1,h2,h3,h4 {
                font-size:rem-calc(25);
                line-height:rem-calc(28);
                color:#B9CE37!important;
            }
        }
        // teaser
        .teaser-text {
            p {
                font-size:rem-calc(15);
                line-height:rem-calc(16);
                color:#fff;
            }
        }
        .news-content-elements {
            .row {
                margin-left:0;
                margin-right: 0;
            }
            p {
                font-size:rem-calc(16);
                line-height:rem-calc(20);
                color:#fff;
            }
        }
        // body text
        .news-text-wrap {
            font-size:rem-calc(16);
            line-height:rem-calc(20);
            p {
                font-size:rem-calc(16);
                line-height:rem-calc(20);
                color:#fff;
            }
        }
        // float: right;
        // width: 282px;
        // img {
        //     float: none;
        // }
        @include breakpoint(medium) {
            // header
            .header {
                width: 50%;
            }
        }
        @include breakpoint(large) {
            // meta
            .meta {
                font-size:rem-calc(28);
                line-height:rem-calc(31);
            }
            // header
            .header {
                width: 50%;
                h1,h2,h3,h4 {
                    font-size:rem-calc(50);
                    line-height:rem-calc(56);
                }
            }
            // teaser
            .teaser-text {
                p {
                    font-size:rem-calc(28);
                    line-height:rem-calc(31);
                }
            }
            .news-content-elements {
                p {
                    font-size:rem-calc(28);
                    line-height:rem-calc(31);
                }
            }
            // body text
            .news-text-wrap {
                font-size:rem-calc(28);
                line-height:rem-calc(31);
                p {
                    font-size:rem-calc(28);
                    line-height:rem-calc(31);
                }
            }
        }
    }
}

.news-img-caption {
    color: $medium-gray;
}
.news-backlink-wrap {
    padding-top: 2rem;
    text-align: center;
}
/* related news + files */
.news-related-wrap {}

.news-related {
    ul {
        margin-bottom: 2rem;
        margin-left: 0;
        list-style-position: outside;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        li {
            position: relative;
            padding-top:100%;
            img {
                position: absolute;
                left:0;
                top:0;
                right:0;
                bottom:0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                vertical-align: middle;
                display: inline-block;
            }
            a {
                display: flex;
                align-items: flex-end;
                position: absolute;
                left:0;
                top:0;
                right:0;
                bottom:0;
                font-size: rem-calc(25);
                line-height: rem-calc(28);
                color: #fff;
                font-weight: bold;
                padding: 15px 15px 25px 15px;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b9ce37+0,b9ce37+100&0+0,1+100 */
                background: -moz-linear-gradient(top,  rgba(185,206,55,0) 0%, rgba(185,206,55,1) 100%); /* FF3.6-15 */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(185,206,55,0)), color-stop(100%,rgba(185,206,55,1))); /* Chrome4-9,Safari4-5 */
                background: -webkit-linear-gradient(top,  rgba(185,206,55,0) 0%,rgba(185,206,55,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: -o-linear-gradient(top,  rgba(185,206,55,0) 0%,rgba(185,206,55,1) 100%); /* Opera 11.10-11.50 */
                background: -ms-linear-gradient(top,  rgba(185,206,55,0) 0%,rgba(185,206,55,1) 100%); /* IE10 preview */
                background: linear-gradient(to bottom,  rgba(185,206,55,0) 0%,rgba(185,206,55,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b9ce37', endColorstr='#b9ce37',GradientType=0 ); /* IE6-9 */
                opacity: 0;
                transition: opacity .3s ease-in-out;
                &:hover {
                    color: #fff!important;
                    opacity: 1;
                }
            }
        }

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);
            li {
                a {
                    font-size: rem-calc(48);
                    line-height: rem-calc(53);
                    padding: 25px 25px 55px 25px;
                }
            }
        }
    }

    h4 {
        margin-bottom: 1rem;
        margin-top: 2rem;
    }
}

.news-related-files {
    .news-related-files-link .jpg {
        background-position: 0 0;
    }
}

.news-clear {
    clear: both;
}
// Layout Dependent CSS

.news {
    .article-rows {
        margin-bottom: 2rem;
    }

    .article-cols {}

    .article-teaser {
        .news-img-wrap {
            float: left;
            display:block;
            width: 100px;
            margin-right: 16px;
        }

        p {
            margin-bottom: 0;
        }
    }
}

.news-shared-wrap {
    padding:20px 0;
    border-top: 1px solid #fff;
    .news-shared {
        display: flex;
        justify-content: space-between;
    }
    .shared-menu {
        a {
            display: inline-block;
            color: #fff;
            margin-right: 8px;
            vertical-align: middle;
            transition: transform .15s ease, text-shadow .3s ease;
            &:last-child {
                margin-right:0;
            }
            img {
                max-height:25px;
                vertical-align: middle;
            }
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}