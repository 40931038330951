$social-font-path: "../fonts" !default;
@font-face {
  font-family: 'social';
  src:
    url('#{$social-font-path}/social.ttf?20181122') format('truetype'),
    url('#{$social-font-path}/social.woff?20181122') format('woff'),
    url('#{$social-font-path}/social.svg?20181122#social') format('svg');
  font-weight: normal;
  font-style: normal;
}

$sicon-blogger: "\e941";
$sicon-deezer: "\e942";
$sicon-facebook: "\e943";
$sicon-flickr: "\e944";
$sicon-foursquare: "\e945";
$sicon-github: "\e946";
$sicon-googleplus: "\e947";
$sicon-instagram: "\e948";
$sicon-linkedin: "\e949";
$sicon-pinterest: "\e94a";
$sicon-skype: "\e94b";
$sicon-snapchat: "\e94c";
$sicon-spotify: "\e94d";
$sicon-tumblr: "\e94e";
$sicon-twitter: "\e94f";
$sicon-vimeo: "\e950";
$sicon-whatsapp: "\e951";
$sicon-wikipedia: "\e952";
$sicon-wordpress: "\e953";
$sicon-xing: "\e954";
$sicon-yelp: "\e955";
$sicon-youtube: "\e956";

[class^="sicon-"], [class*=" sicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sicon-blogger {
  &:before {
    content: $sicon-blogger;
  }
}
.sicon-deezer {
  &:before {
    content: $sicon-deezer;
  }
}
.sicon-facebook {
  &:before {
    content: $sicon-facebook;
  }
}
.sicon-flickr {
  &:before {
    content: $sicon-flickr;
  }
}
.sicon-foursquare {
  &:before {
    content: $sicon-foursquare;
  }
}
.sicon-github {
  &:before {
    content: $sicon-github;
  }
}
.sicon-googleplus {
  &:before {
    content: $sicon-googleplus;
  }
}
.sicon-instagram {
  &:before {
    content: $sicon-instagram;
  }
}
.sicon-linkedin {
  &:before {
    content: $sicon-linkedin;
  }
}
.sicon-pinterest {
  &:before {
    content: $sicon-pinterest;
  }
}
.sicon-skype {
  &:before {
    content: $sicon-skype;
  }
}
.sicon-snapchat {
  &:before {
    content: $sicon-snapchat;
  }
}
.sicon-spotify {
  &:before {
    content: $sicon-spotify;
  }
}
.sicon-tumblr {
  &:before {
    content: $sicon-tumblr;
  }
}
.sicon-twitter {
  &:before {
    content: $sicon-twitter;
  }
}
.sicon-vimeo {
  &:before {
    content: $sicon-vimeo;
  }
}
.sicon-whatsapp {
  &:before {
    content: $sicon-whatsapp;
  }
}
.sicon-wikipedia {
  &:before {
    content: $sicon-wikipedia;
  }
}
.sicon-wordpress {
  &:before {
    content: $sicon-wordpress;
  }
}
.sicon-xing {
  &:before {
    content: $sicon-xing;
  }
}
.sicon-yelp {
  &:before {
    content: $sicon-yelp;
  }
}
.sicon-youtube {
  &:before {
    content: $sicon-youtube;
  }
}


ul.social_buttons_list {
    margin-left: 0;
    list-style: none !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    & > * {
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 0;
        display: block;
    }

    li {
        margin: 0 12px 12px 0;

        &:last-child {
            margin-right: 0;
        }

        a {
            display: block;
            width: 36px;
            height: 36px;

            i {
                display: block;
                width: 36px;
                height: 36px;
                background-color: $dark-gray;
                color: $white;

                &:before {
                    display: block;
                    width: 36px;
                    height: 36px;
                    text-align: center;
                    line-height: 36px;
                    vertical-align: middle;
                    font-size: 24px;
                }
            }

            &:hover {
                text-decoration: none;
                i {
                    background-color: rgba($dark-gray, 0.5);
                }
            }
        }
    }
}

.dark{
    ul.social_buttons_list li a  {
        i {
            background-color: $white;
            color: $black;
        }
        &:hover {
            i {
                background-color: rgba($white, 0.5);
            }
        }
    }
}



.social-menu {
  visibility: visible;
  display:flex;
  justify-content:space-between;
  align-items:center;
  font-size:18px;
  line-height:18px;
  a {
    display: inline-block;
    color:#fff!important;
    margin-right:8px;
    vertical-align: middle;
    transition: transform .15s ease, text-shadow .3s ease;
    &:last-child {
      margin-right:0;
    }
    i {
      vertical-align: middle;
    }
    &:hover {
      color:#fff;
      transform: scale(1.1);
    }
  }

  @include breakpoint(large) {
    font-size:24px;
    line-height:24px;
  }

  &.social-menu-footer {
    display:none;
    visibility: hidden;
    a:hover {
      text-shadow: 1px 1px 2px rgba(0,0,0,.25);
    }
    @include breakpoint(medium) {
      visibility: visible;
      display:flex;
    }
  }
}