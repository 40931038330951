/**
  TWE Special sections
 */


/* twe section 01 */
.twe-section-01 {
  position:relative;
  &:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: #EAEAEA;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 33% 100%, 0 85%);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 79%, 31% 100%, 0 93%);
    }
  }
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 74%, 31% 99%, 0 87%);
    }

    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      clip-path: polygon(100% 0, 100% 75%, 25% 100%, 0 90%, 0 0);
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#102e48+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(left,  rgba(16,46,72,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(16,46,72,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102e48', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
      @include breakpoint(medium) {
        clip-path: polygon(0 0, 100% 0, 100% 74%, 31% 99%, 0 87%);
      }
    }
  }
}


/* twe section 02 */
.twe-section-02 {
  position:relative;
  @include breakpoint(medium) {
    padding: 30px 0 20px 0;
  }
  &:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:#f5f8e1;
    clip-path: polygon(0 0, 100% 0, 100% 97%, 68% 100%, 0 97%);
    @include breakpoint(medium) {
      clip-path: polygon(0 6%, 23% 0, 100% 9%, 100% 92%, 78% 100%, 0 92%);
    }
  }
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 97%, 68% 100%, 0 97%);
    @include breakpoint(medium) {
      clip-path: polygon(0 3%, 23% 0, 100% 6%, 100% 95%, 77% 99%, 0 92%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b9ce37+0,b9ce37+100&1+0,0+100 */
      background: -moz-linear-gradient(left,  rgba(185,206,55,1) 0%, rgba(185,206,55,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(185,206,55,1)), color-stop(100%,rgba(185,206,55,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(185,206,55,1) 0%,rgba(185,206,55,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(185,206,55,1) 0%,rgba(185,206,55,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(185,206,55,1) 0%,rgba(185,206,55,0) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(185,206,55,1) 0%,rgba(185,206,55,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9ce37', endColorstr='#00b9ce37',GradientType=1 ); /* IE6-9 */
    }
  }
}


/* twe section 03 */
.twe-section-03 {
  .twe-section-overlay {
    position: relative;
    overflow: hidden;
    &:before,
    &:after {
      content:'';
      display:block;
      position: absolute;
      left: 0;
      right:0;
      height:60px;
    }
    &:before {
      top: 0;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
      background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* IE10 preview */
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    }
    &:after {
      bottom:0;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* IE10 preview */
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    }
    .section__inner {
      z-index: 1;
    }
  }
}


/* twe section 04 */
.twe-section-04 {
  position:relative;
  @include breakpoint(medium) {
    padding: 0 0 20px 0;
  }
  &:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:#EAEAEA;
    clip-path: polygon(100% 0, 100% 92%, 25% 100%, 0 96%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 64% 0, 100% 7%, 100% 98%, 61% 100%, 0 92%);
    }
  }
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 92%, 25% 100%, 0 96%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 64% 0, 100% 7%, 100% 100%, 61% 100%, 0 94%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#102e48+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(left,  rgba(16,46,72,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(16,46,72,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102e48', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
    }
  }
}


/* twe section 05 */
.twe-section-05 {
  &.section_bg-image {
    .twe-section-overlay {
      @include breakpoint(medium) {
        background-size: contain;
      }
    }
  }
}


/* twe section 06 */
.twe-section-06 {
  position:relative;
  @include breakpoint(medium) {
    padding: 10px 0 0 0;
  }
  &:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:#f5f8e1;
    clip-path: polygon(100% 0, 100% 94%, 25% 100%, 0 98%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 11%, 75% 0, 100% 6%, 100% 85%, 9% 97%, 0 90%);
    }
  }
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    background-color: #B9CE37;
    clip-path: polygon(100% 0, 100% 94%, 25% 100%, 0 98%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 7%, 20% 0, 100% 11%, 100% 93%, 78% 100%, 0 90%);
    }
    //&:before {
    //  position: absolute;
    //  z-index: 0;
    //  display:block;
    //  content:"";
    //  left:0;
    //  top:0;
    //  right:0;
    //  bottom:0;
    //  overflow: hidden;
    //}
  }
}


/* twe section 07 */
.twe-section-07 {
  .twe-section-overlay {
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      height:150px;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dddddd+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(top,  rgba(241,242,242,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(241,242,242,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(top,  rgba(241,242,242,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(top,  rgba(241,242,242,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(top,  rgba(241,242,242,1) 0%,rgba(255,255,255,0) 100%); /* IE10 preview */
      background: linear-gradient(to bottom,  rgba(241,242,242,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dddddd', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    }
  }
}


/* twe section 08 [subpages]*/
.twe-section-08 {
  position:relative;
  &:before {
    position: absolute;
    z-index: 0;
    display:block;
    content:"";
    left:0;
    top:0;
    right:0;
    bottom:0;
    background: #EAEAEA;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 30% 100%, 0 88%);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 70%, 29% 100%, 0 90%);
    }
  }
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    background: #102E48;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 42% 100%, 0 80%);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 62%, 29% 100%, 0 82%);
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#103454+0,041422+100&1+0,0.2+100 */
      background: -moz-linear-gradient(left,  rgba(16,52,84,1) 0%, rgba(4,20,34,0.2) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(16,52,84,1)), color-stop(100%,rgba(4,20,34,0.2))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(16,52,84,1) 0%,rgba(4,20,34,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(16,52,84,1) 0%,rgba(4,20,34,0.2) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(16,52,84,1) 0%,rgba(4,20,34,0.2) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(16,52,84,1) 0%,rgba(4,20,34,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#103454', endColorstr='#33041422',GradientType=1 ); /* IE6-9 */
    }
  }
  .section__inner {
     z-index:1;
  }
}


/* twe section 09 */
.twe-section-09 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 68% 100%, 0 85%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      clip-path: polygon(100% 0, 100% 75%, 25% 100%, 0 90%, 0 0);
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#102e48+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(left,  rgba(16,46,72,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(16,46,72,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102e48', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
      @include breakpoint(medium) {
        clip-path: polygon(0 0, 100% 0, 100% 86%, 68% 100%, 0 81%);
      }
    }
  }
}


/* twe section 10 */
.twe-section-10 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 99%, 25% 100%, 0 99%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      clip-path: polygon(100% 0, 100% 99%, 25% 100%, 0 99%, 0 0);
      background: transparent linear-gradient(90deg, #B9CE37 0%, rgba(185, 206, 55, 0.25) 100%) 0% 0% no-repeat padding-box;
      opacity: 1;
      @include breakpoint(medium) {
        clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
      }
    }
  }
}


/* twe section 11 */
.twe-section-11 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      opacity: .96;
      clip-path: polygon(100% 0, 100% 75%, 25% 100%, 0 90%, 0 0);
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7d99be+0,5c7391+64,5c7391+100&1+0,0.5+61,0.9+100 */
      background: -moz-linear-gradient(left,  rgba(125,153,190,1) 0%, rgba(94,117,147,0.5) 61%, rgba(92,115,145,0.53) 64%, rgba(92,115,145,0.9) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(125,153,190,1)), color-stop(61%,rgba(94,117,147,0.5)), color-stop(64%,rgba(92,115,145,0.53)), color-stop(100%,rgba(92,115,145,0.9))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(125,153,190,1) 0%,rgba(94,117,147,0.5) 61%,rgba(92,115,145,0.53) 64%,rgba(92,115,145,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(125,153,190,1) 0%,rgba(94,117,147,0.5) 61%,rgba(92,115,145,0.53) 64%,rgba(92,115,145,0.9) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(125,153,190,1) 0%,rgba(94,117,147,0.5) 61%,rgba(92,115,145,0.53) 64%,rgba(92,115,145,0.9) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(125,153,190,1) 0%,rgba(94,117,147,0.5) 61%,rgba(92,115,145,0.53) 64%,rgba(92,115,145,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d99be', endColorstr='#e65c7391',GradientType=1 ); /* IE6-9 */
      @include breakpoint(medium) {
        clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
      }
    }
  }
}


/* twe section 12 */
.twe-section-12 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 68% 100%, 0 85%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#102e48+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(left,  rgba(16,46,72,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(16,46,72,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102e48', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
      @include breakpoint(medium) {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 68% 100%, 0 85%);
      }
    }
  }
}

/* twe section 13 */
.twe-section-13 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
    background-color: #102e48;
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 68% 100%, 0 85%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      opacity: .4;
      clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#102e48+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(left,  rgba(16,46,72,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(16,46,72,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgba(16,46,72,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102e48', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
      @include breakpoint(medium) {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 68% 100%, 0 85%);
      }
    }
  }
}


/* twe section 14 */
.twe-section-14 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      clip-path: polygon(100% 0, 100% 75%, 25% 100%, 0 90%, 0 0);
      opacity: 1;
      @include breakpoint(medium) {
        clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
      }
    }
  }
}

/* twe section 15 */
.twe-section-15 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 68% 100%, 0 95%);
    background: #102E48;
    @include breakpoint(medium) {
      //clip-path: polygon(0 4%, 26% 0, 100% 5%, 100% 97%, 75% 100%, 0 94%);
      clip-path: polygon(0 2%, 26% 0, 100% 3%, 100% 99%, 75% 100%, 0 96%);
    }
  }
}

.twe-section-16 {
  .twe-section-overlay {
    position: relative;
    overflow: hidden;
    background: #102E48;
    clip-path: polygon(0 0, 100% 0, 100% 97%, 68% 100%, 0 97%);
    @include breakpoint(medium) {
      clip-path: polygon(0 6%, 20% 0, 100% 9%, 100% 100%, 0 100%);
    }
  }
}
.twe-section-17 {
  .twe-section-overlay {
    position: relative;
    overflow: hidden;
    background-color: #F4F4F4;
    @include breakpoint(medium) {
      clip-path: polygon(0 4%, 19% 0, 100% 2%, 100% 97%, 8% 100%, 0 99%);
    }
    @include breakpoint(large) {
      clip-path: polygon(0 8%, 19% 0, 100% 6%, 100% 92%, 8% 100%, 0 96%);
    }
  }
}



/* twe section 18 */
.twe-section-18 {
  .twe-section-overlay {
    position:relative;
    overflow: hidden;
    clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
    @include breakpoint(medium) {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 68% 100%, 0 85%);
    }
    .section__inner {
      z-index:1;
    }
    &:before {
      position: absolute;
      z-index: 0;
      display:block;
      content:"";
      left:0;
      top:0;
      right:0;
      bottom:0;
      overflow: hidden;
      opacity: .7;
      clip-path: polygon(100% 0, 100% 78%, 25% 100%, 0 90%, 0 0);
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#102e48+0,ffffff+100&1+0,0+100 */
      background: -moz-linear-gradient(left,  rgb(185, 206, 55) 0%, rgba(185, 206, 55, 0) 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgb(185, 206, 55)), color-stop(100%,rgba(185, 206, 55,0))); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(left,  rgb(185, 206, 55) 0%,rgba(185, 206, 55,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(left,  rgb(185, 206, 55) 0%,rgba(185, 206, 55,0) 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(left,  rgb(185, 206, 55) 0%,rgba(185, 206, 55,0) 100%); /* IE10 preview */
      background: linear-gradient(to right,  rgb(185, 206, 55) 0%,rgba(185, 206, 55,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#B9CE37', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
      //background: transparent linear-gradient(90deg, var(--unnamed-color-b9ce37) 0%, #B9CE3700 100%) 0% 0% no-repeat padding-box;
      //background: transparent linear-gradient(90deg, #B9CE37 0%, #B9CE3700 100%) 0% 0% no-repeat padding-box;
      @include breakpoint(medium) {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 68% 100%, 0 85%);
      }
    }
  }
}

/* twe section news */
.twe-section-news {
  background:transparent;
  position: relative;
  padding-top: 72px;
  > .twe-section-overlay > .section__inner,
  > .section__inner {
    padding-bottom: 72px;
  }
  &:before {
    content:'';
    display:block;
    top:0;
    bottom:50px;
    left:0;
    right:0;
    background:#102E48;
    position:absolute;
  }
  &:after {
    content:'';
    display:block;
    height: 50px;
    width: 100%;
    background:#102E48;
    clip-path: polygon(0 0, 100% 0, 33% 100%, 0 10%);
  }
  @include breakpoint(medium) {
    padding-top: 76px;
    > .twe-section-overlay > .section__inner,
    > .section__inner {
      padding-bottom: 76px;
    }
    &:after {
      height: 68px;
      clip-path: polygon(0 0, 100% 0, 100% 28%, 86% 100%);
    }
    &:before {
      bottom: 68px;
    }
  }
  @include breakpoint(large) {
    padding-top: 86px;
    > .twe-section-overlay > .section__inner,
    > .section__inner {
      padding-bottom: 86px;
    }
  }
}



//.twe-slick.twe-slick-03 {
//  .slick-track {
//    .slick-slide {
//      > div {
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        > div {
//          position: relative;
//          overflow: hidden;
//          clip-path: polygon(100% 0, 100% 75%, 25% 100%, 0 90%, 0 0);
//          @include breakpoint(medium) {
//            clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
//          }
//          &:before {
//            position: absolute;
//            z-index: 0;
//            display: block;
//            content: "";
//            left: 0;
//            top: 0;
//            right: 0;
//            bottom: 0;
//            overflow: hidden;
//            clip-path: polygon(100% 0, 100% 75%, 25% 100%, 0 90%, 0 0);
//            background: transparent linear-gradient(90deg, #B9CE37 0%, rgba(185, 206, 55, 0.25) 100%) 0% 0% no-repeat padding-box;
//            opacity: 1;
//            @include breakpoint(medium) {
//              clip-path: polygon(0 6%, 76% 0, 100% 5%, 100% 97%, 10% 100%, 0 97%);
//            }
//          }
//        }
//      }
//    }
//  }
//}